import React from "react";
import "../css-files/about-style.css";
import Footer from "../components/footer/footer";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "./mobile-social-links";
import design from "../images/about-us/user-engagement.png";
import ui from "../images/about-us/ui.png";
import seo from "../images/about-us/seo.png"
import responsive from "../images/about-us/responsive.png";
import visual from "../images/about-us/visual-effects.png"
import image from "../../src/images/hidden/scalability.png";
import imagetwo from "../../src/images/hidden/cross-platform.png";
import imagethree from "../../src/images/hidden/data-driven.png";
import benifit from "../images/Home/meeting.png"
import { IoIosArrowForward } from "react-icons/io";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Blogpage = () => {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Elevating Your Online Presence: Unleashing the Power of Web Design
            with Honeyiconics
          </title>
          <meta
            name="description"
            content="Enhance the visual aspect of your brand by working with a graphic design company in Coimbatore. We create graphically attractive designs that boost your brand's visibility and engage your audience with attention to detail and creativity"
          />
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/website-graphic-design-services"
          />
        </Helmet>
      </div>
      <section>
        <div>
          <section className="wrapper mb-10 pb-10">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 max-lg:flex-col">
              <div className="w-[100%] text-[3.8rem] pt-20 max-lg:w-full md:px-16 max-lg:pl-none max-md:px-6  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  max-sm:text-[2rem] text-white "
                >
                  <span>Web designing</span>
                </h1>
                <p className="mt-6 md:px-18 max-sm:text-[15px]  text-[18px] font-RobatoCondesed text-center text-white ">
                  Searching For Best Website Development Services In India To
                  Create A Modern Website For Your Business Or Startup? Take
                  Quick Action And Hire HoneyIconics A Leading Website
                  Development Company In India For The Best Website Development
                  Services At Affordable Prices.
                </p>

                <center>
                  <Link to={"/contact"}>
                    {" "}
                    <button class="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us{" "}
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>

        {/* ======================================== */}
        <div>
          <div className="px-16 font-RobatoCondesed max-md:px-6 mb-10">
            <div>
              <h2 className="mt-10 uppercase text-[35px] max-sm:text-[25px] text-start ">
                <span className=" font-serif text-orange-500">
                  Introduction
                </span>
              </h2>

              <p className="mt-5 mb-15 font-normal text-justify  text-[18px]">
                In today's digital landscape, a well-designed website is crucial
                for attracting and engaging visitors. Join us as we explore the
                key elements of effective web design and how Honeyiconics can
                help you make a lasting impression in the digital world.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* =================================================== */}
      <div className="key-page-section px-16 mb-10 pb-1 pt-3">
        <h2 className="mt-10 uppercase text-[35px] max-sm:text-[25px] text-center ">
          <span className="ml-3 pt-16 font-serif text-orange-500">
            Key Features
          </span>
        </h2>
        <div className="key-container">
          <div className="key-box">
            <div className="key-img">
              <div className="key-imgone">
                <h2 className="font-RobatoCondesed text-[18px] max-sm:text-[15px]">
                  <span>User-Centric Design</span>
                  <center className="mt-3"><img src={ui} alt="design" /></center>
                  <br />
                  <br />
                  <p>At Honeyiconics, we prioritize user experience (UX) design,
                  ensuring that every aspect of your website is intuitive,
                  accessible, and visually appealing. From navigation menus to
                  interactive elements, we focus on creating a seamless and
                  enjoyable browsing experience for your visitors.</p>
                </h2>
              </div>
              <div className="key-imgone">
                <h2 className="font-RobatoCondesed text-[18px] max-sm:text-[15px]">
                  <span>Mobile Responsiveness</span>
                  <center className="mt-3"><img src={responsive} alt="design" /></center>
                  <br />
                  <br />
                  <p>With the increasing use of mobile devices, we emphasize
                  responsive web design to ensure that your website looks and
                  functions flawlessly across all screen sizes and devices. This
                  mobile-friendly approach enhances usability and accessibility,
                  catering to a diverse audience.</p>
                </h2>{" "}
              </div>
              <div className="key-imgone">
                <h2 className="font-RobatoCondesed  text-[18px] max-sm:text-[15px]">
                  <span>Visual Aesthetics</span>
                  <center className="mt-3"><img src={visual} alt="design" /></center>
                  <br />
                  <br />
                  <p>Our team of designers excels in crafting stunning visual
                  elements, including graphics, images, and typography, that
                  reflect your brand identity and captivate your audience. We
                  leverage color schemes, typography choices, and imagery to
                  create a cohesive and impactful design that resonates with
                  your target market.</p>
                </h2>{" "}
              </div>
              <div className="key-imgone">
                <h2 className="font-RobatoCondesed text-[18px] max-sm:text-[15px]">
                  <span>SEO Optimization</span>
                  <center className="mt-3"><img src={seo} alt="design" /></center>
                  <br />
                  <br />
                 <p>We integrate SEO best practices into our web design process,
                  optimizing your site's structure, metadata, and content to
                  improve search engine visibility and rankings. Our goal is to
                  help your website attract organic traffic and enhance its
                  online presence.</p>
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================================================== */}
      {/* benifits  */}
      <div className="benifit-page-section px-16 max-md:px-4 dark:bg-gray-800">
      <h2 className="mt-20 mb-1 uppercase text-[35px] max-sm:text-[25px] text-center ">
          <span className="ml-3 pt-16 font-serif text-orange-500">
            Benefits
          </span>
        </h2>
        <div className="benifit-container">
          <div className="benifit-box">
            <div className="benifit-img">
              <div className="benifit-imgone">
                <img src={benifit} alt="benifit" />
              </div>
              <div className="benifit-imgtwo">
                <div className="benifit-one">
                  <h3>Professional Image</h3>
                  <p className="font-RobatoCondesed text-[18px] max-sm:text-[15px]"> A well-designed website conveys professionalism and credibility, making a positive first impression on visitors and potential customers. It reflects your brand's identity, values, and quality standards, establishing trust and confidence in your offerings.</p>
                </div>
                <div className="benifit-one">
                  <h3>Improved User Engagement</h3>
                  <p className="font-RobatoCondesed text-[18px] max-sm:text-[15px]"> User-friendly design and intuitive navigation encourage visitors to explore your website, discover relevant content, and take desired actions such as making purchases or contacting your business. This increased engagement leads to higher conversion rates and customer satisfaction.</p>
                </div>
                <div className="benifit-one">
                  <h3>Brand Consistency</h3>
                  <p className="font-RobatoCondesed text-[18px] max-sm:text-[15px]">Consistent branding elements, visual styles, and messaging across your website create a cohesive brand experience for visitors. It reinforces brand recognition, strengthens brand loyalty, and fosters a sense of familiarity and trust among your audience.</p>
                </div>
                <div className="benifit-one">
                  <h3>Competitive Advantage</h3>
                  <p className="font-RobatoCondesed text-[18px] max-sm:text-[15px]"> In a competitive digital landscape, a well-designed website sets you apart from competitors and positions your brand as a leader in your industry. It helps you stand out, attract attention, and stay ahead of market trends and customer expectations.</p>
                </div>
                <div className="benifit-two"></div>
                <div className="benifit-three"></div>
                <div className="benifit-four"></div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================================================== */}

      {/* ====================================================== */}
      <section className="px-16 py-6 max-md:px-4 dark:bg-gray-800 sec-four">
        <h2 className="mt-10 uppercase text-[35px] max-sm:text-[25px] text-center pb-5">
          <span className="ml-3 font-serif text-orange-500">Advantage</span>
        </h2>
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
            <div className="card1 text-center">
              <img
                src={image}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7 images-card"
              />
              <h3 className="font-semibold card-heading text-center max-sm:text-[23px] text-[35px] font-Opensanfour">
                Data-Driven Optimization
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small max-sm:text-[16px]">
                      We leverage data analytics and user feedback to
                      continuously optimize and improve your website's
                      performance, functionality, and conversion rates. Our
                      iterative approach focuses on data-driven decision-making
                      and ongoing enhancements to maximize results.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
            {/* ========================================= */}
            <div className="card1 text-center">
              <img
                src={imagetwo}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7 images-card"
              />
              <h3 className="font-semibold card-heading text-center max-sm:text-[23px] text-[35px] font-Opensanfour">
                Cross-Platform Compatibility
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small max-sm:text-[16px]">
                      We optimize your website for cross-platform compatibility,
                      ensuring seamless performance across browsers, devices,
                      and operating systems. This compatibility enhances
                      accessibility and user experience for a broader audience.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
            {/* ========================================= */}
            <div className="card1 text-center">
              <img
                src={imagethree}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7 images-card"
              />
              <h3 className="font-semibold card-heading text-center max-sm:text-[23px] text-[35px] font-Opensanfour">
                Scalability
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small max-sm:text-[16px]">
                      Our web design solutions are scalable and adaptable to
                      your business growth and evolving needs. Whether you're
                      launching a new website or redesigning an existing one, we
                      ensure scalability and flexibility to accommodate future
                      updates, expansions, and enhancements.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
          </div>
        </div>
      </section>

      {/* ========================================================= */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
          <h2 className="mt-10 mb-1 uppercase text-[35px] max-sm:text-[25px] text-start ">
          <span className="pt-16 font-serif text-orange-500 ">
            Conclusion
          </span>
        </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              In conclusion, Honeyiconics is your trusted partner for creating
              impactful web designs that elevate your online presence, engage
              your audience, and drive business success. With our user-centric
              approach, mobile responsiveness, visual aesthetics, SEO
              optimization, and a focus on benefits such as professionalism,
              engagement, brand consistency, and competitive advantage, we
              empower you to achieve your web design goals and deliver
              exceptional digital experiences. Let's collaborate to transform
              your website into a powerful asset that reflects the essence of
              your brand and captivates your visitors.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* ============================================================ */}
      {/* ========================================================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What does Honey Iconics specialize in ?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Honey Iconics is a leading website development company based
                    in Coimbatore. We specialize in a range of digital services,
                    including website development, SEO (search engine
                    optimization), logo design, etc.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can you tell us more about your website development
                    services?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Certainly! We take pride in crafting custom websites
                    tailored to the unique needs of our clients. Our team of
                    experienced developers ensures that your website is not only
                    visually appealing but also highly functional and
                    user-friendly.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What is SEO, and how does Honey Iconics help businesses with
                    it?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    SEO, or Search Engine Optimization, is the process of
                    optimizing your website to rank higher in search engine
                    results. At Honey Iconics, we provide SEO services that
                    improve your website's visibility, driving organic traffic
                    and helping you reach your target audience effectively.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Do you offer logo design services as well?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, we do! Our skilled designers create memorable and
                    impactful logos that represent your brand's identity. A
                    well-designed logo can leave a lasting impression on your
                    audience, and we're here to ensure it's a positive one.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}

      <Footer />
      <MobileSocialIcons />
    </>
  );
};

export default Blogpage;
