import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import ImageOne from "../images/about-us/Image-one.jpg";
// import ImageTwo from "../images/about-us/Image-two.jpg";
// import ImageThree from "../images/about-us/Thoughts.gif";
import "../css-files/about-style.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Owner from "../images/ceo/photo.jpg";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import Footer from "../components/footer/footer";
import MobileSocialIcons from "./mobile-social-links";
const AboutUs = () => {
  return (
    <>
   <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            About Us - Leading Software Development Company in Coimbatore
          </title>

          <meta
            name="description"
            content="Discover Honey Iconics-Coimbatore's trusted software development company. We deliver innovative solutions for your digital needs."
          />
          <link
            rel="canonical"
            href="https://honey-iconics/about-us"/>
        </Helmet>
      </div>{" "}

      
      <section>
        <div>
          <section className="wrapper-two h-[300px] max-md:h-auto">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
            <div className="h-auto mt-5 mb-5 ">
              <div className="row">
                <div className="text-center ">
                  <Link to={"about-us"}>
                    {" "}
                    <h3 className="animate-charcter text-[150px] max-sm:text-[80px]">
                      About Us
                    </h3>{" "}
                  </Link>

                  <center></center>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* //////// banner end ////// */}
        <section>
          <div className="px-16 mt-16 max-sm:px-5 BagroundimageAboutus">
            <h1 className="text-[35px] max-sm:text-[28px] uppercase text-center font-RobatoCondesed">
              Digital marketing company in - require about our company{" "}
              <br className="mob-1" />
              <span className="ml-2 mr-2 font-serif text-orange-500">
                Coimbatore
              </span>{" "}
              <br className="none" />
              All the information you
            </h1>

            <div className="flex mt-10 max-lg:flex-col ">
              <div className="w-[50%] h-auto max-lg:w-[100%]">
                <img
                  className="object-cover"
                  src={ImageOne}
                  alt="loading"
                  title="meeting"
                />
              </div>

              <div className="w-[50%] h-auto  pt-5 max-lg:w-[100%] font-RobatoCondesed">
                <h2 className="text-[18px]">
                  Our company is a creative design firm
                </h2>
                <h2 className="text-[35px] text-orange-500 font-bold uppercase">
                  About Honey Iconics
                </h2>

                <div className="about-founter">
                  <img src={Owner} alt="CEO" title="founder" />
                  <div className="about-ceo">
                    <h3>Mr. Arul Muniyappan</h3>
                    <h4>Founder & CEO at Honey Iconics</h4>
                  </div>
                </div>
                <p className="mt-5 font- text-justify text-[18px]  font-RobatoCondesed">
                  Presenting{" "}
                  <span className=" text-orange-500 font-bold">
                    {" "}
                    Mr. Arul Muniyappan
                  </span>
                  , the man behind Honey Iconics, has been leading us since
                  2023. He adds a wealth of expertise in digital marketing and
                  web design to his role as CEO and Founder. Honey Iconics,
                  presented as Coimbatore's top digital marketing agency,
                  changes the online environment. Our goal is to provide users
                  with valuable and productive online experiences by mastering
                  software, web design, and app development, as well as SEO.
                  Join us as we explore your brand's online potential.
                </p>
              </div>
            </div>
            <p className="text-justify text-[18px] font-RobatoCondesed ">
              Join Honey Iconics as they take you on a digital experience where
              innovation and quality meet. Get our assistance in changing your
              online presence and achieving unmatched success. Get in touch with
              us right now, and let's work together to develop something great!
            </p>
          </div>
        </section>


        {/* ================== Why Choos us ======================*/}


        <div className="mt-10 mb-10 text-center">
          <center>
            <h3 className="text-[35px] mb-10 uppercase font-RobatoCondesed">
              Our Working
              <span className="ml-2 text-orange-500">Process </span>
            </h3>
          </center>
        </div>
        <section className="container learn-section">
          <div className="tl ">
            <div className=" tl-container tl-left">
              <div className="shadow-lg tl-content shadow-gray-900">
                <h2 className="text-[23px] uppercase font-RobatoCondesed  tracking-wider">
                  1. Planning
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 rounded-2xl max-md:w-auto"></div>
                <p className="text-[18px] text-justify font-RobatoCondesed">
                  This phase involves defining project objectives, outlining the
                  scope, and setting a strategic direction. It includes tasks
                  like goal setting, resource allocation, and creating a project
                  plan.
                </p>
              </div>
            </div>

            <div className="tl-container tl-right">
              <div className="shadow-lg tl-content font-RobatoCondesed shadow-gray-900">
                <h2 className="text-[23px] uppercase   tracking-wider">
                  2.Design Phase
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 max-md:w-auto rounded-2xl"></div>
                <p className="text-[18px] text-justify">
                  In the design phase of web development, wireframing and
                  prototyping are pivotal stages that define the website's
                  structure and user interaction. The integration of visual
                  elements, including color schemes, typography, and graphics,
                  contributes to the site's visual appeal and brand identity.
                </p>
              </div>
            </div>

            <div className="tl-container tl-left">
              <div className="shadow-lg font-RobatoCondesed tl-content shadow-gray-900">
                <h2 className="text-[23px] uppercase   tracking-wider">
                  3. Development Phase
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 max-md:w-auto rounded-2xl"></div>
                <p className="text-[18px] text-justify">
                  A development update, providing an update of tasks finished,
                  ongoing operations, and any difficulties observed during the
                  development phase, is a crucial tool for project managers.
                  Improved transparency, educated decision-making, and
                  stakeholder communication all help the project move forward
                  successfully in the end.
                </p>
              </div>
            </div>

            <div className="tl-container tl-right">
              <div className="shadow-lg font-RobatoCondesed tl-content shadow-gray-900">
                <h2 className="text-[23px] uppercase tracking-wider">
                  4. Testing
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 max-md:w-auto rounded-2xl"></div>
                <p className="text-[18px] text-justify">
                  To ensure the project's overall reliability and quality
                  throughout the testing phase, we mainly work on quality
                  control, functional testing, user experience assessment, and
                  tracking issues and resolution.
                </p>
              </div>
            </div>

            <div className="tl-container tl-left">
              <div className="shadow-lg font-RobatoCondesed tl-content shadow-gray-900">
                <h2 className="text-[23px] uppercase tracking-wider">
                  5. Launch
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 max-md:w-auto rounded-2xl"></div>
                <p className="text-[18px] text-justify">
                  Following successful testing, the project is delivered and
                  deployed to the customer for their use. This marks the moment
                  of transition, where your project goes live and becomes
                  accessible to your audience, ready to make a meaningful impact
                </p>
              </div>
            </div>

            <div className="tl-container tl-right">
              <div className="shadow-lg tl-content font-RobatoCondesed shadow-gray-900">
                <h2 className="text-[23px] uppercase tracking-wider">
                  6. Promote
                </h2>
                <div className="w-64 h-1 mt-3 mb-2 bg-orange-500 max-md:w-auto rounded-2xl"></div>
                <p className="text-[18px] text-justify">
                  In the promotion phase, our mission is to assist our customers
                  in effectively promoting their websites to captivate and
                  engage their specific target audience. Our strategies are
                  carefully tailored to ensure your online presence resonates
                  with the audience you aim to reach, facilitating meaningful
                  connections in the digital landscape.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* ================== MISSION AND VISION OF HONEY ICONICS ======================*/}

        <section className="py-10 bg-gray-100">
          <div className="px-16 mt-16 max-md:px-2">
            <h2 className="uppercase text-center text-[32px]  font-RobatoCondesed">
              Fundamental Principles of
              <span className="ml-2 font-serif text-orange-500 ">
                Honey Iconics
              </span>
            </h2>
            <center>
              <div className="h-1 mt-3 bg-orange-500 rounded-full w-96 max-md:w-auto"></div>
            </center>
            <div className="flex gap-6 pb-10 mt-10 max-lg:flex-col">
              <div className="w-[50%] max-lg:w-full">
                <div className="font-RobatoCondesed">
                  <h2 className="text-[30px] text-orange-500 text-center uppercase font-bold">
                    Our vision
                  </h2>

                  <p className="mt-5 text-[18px] text-justify leading-8">
                    Our goal at Honey Iconics is to enable people and companies
                    through creative and thoughtful web development solutions.
                    Our commitment lies in creating exceptional digital
                    experiences that surpass clients' expectations, resulting in
                    their success, and establishing lasting interactions within
                    the online community.
                  </p>
                </div>
              </div>
              <div className="w-[50%] font-RobatoCondesed  max-lg:w-full">
                <h2 className="text-[30px] text-orange-500 text-center uppercase font-bold">
                  Our Mission
                </h2>

                <p className="mt-5 text-[18px] text-justify leading-8">
                  At Honey Iconics, we want to be a positive force in digital
                  progress. In the future, we see each online presence we build
                  exceeding our client's changing needs, not just meeting them.
                  With a dedication to quality, honesty, and ongoing innovation,
                  our goal is to become a reliable partner in defining the web
                  development industry's future.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* ============================= Our Journy ===================================== */}
        <section>
          <div className="px-16 max-md:px-3 font-RobatoCondesed bg-slate-900">
            <h2 className="py-10 uppercase font-medium text-center text-orange-400 text-[30px]">
              The most trusted website design company in Coimbatore
            </h2>
            <p className="text-center text-white text-[18px] leading-8">
              Honey Iconics stands as the most trusted website design company in
              Coimbatore, driven by a commitment to excellence and client
              satisfaction. With a team of skilled professionals, we bring
              creativity, transparency, and technical proficiency to every
              project. Our client-centric approach ensures clear communication
              and tailored solutions, reflecting our dedication to meeting
              unique business needs. Partner with us for a reliable and
              innovative web design experience, where your trust is our top
              priority. Elevate your online presence with Honey Iconics, your
              trusted digital design partner in Coimbatore.
            </p>

            <h2 className="py-10 text-[30px] font-medium text-center text-orange-400">
              OUR JOURNEY
            </h2>
            <p className="text-center text-white text-[18px] leading-8 pb-10">
              After more than five years at Honey Iconics, we've grown from
              being a vision to becoming an established force in Coimbatore's
              online design industry. Our love for modern digital experiences
              has driven us to continue growing on this path. We've accepted new
              technologies, developed our skills, and built long-term
              connections with companies of all sizes with each project. We are
              grateful for our client's trust in us as we celebrate this
              achievement. Come along with us at Honey Iconics as we create
              fantastic web design solutions by combining creativity,
              experience, and dedication.
            </p>
          </div>
        </section>

        {/* ============================================================================ */}
        <section className="px-16 py-6 max-md:px-4 dark:bg-gray-800">
          <div className="container flex flex-col justify-center p-4 mx-auto">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
              <div>
                <div className="card1 ">
                  <h3 className="font-semibold card-heading text-[35px] font-Opensanfour">
                    Web
                  </h3>
                  <h3 className="font-semibold card-heading text-[35px] font-RobatoCondesed">
                    Designing
                  </h3>
                  <div>
                    <ul className="mt-3 font-serif text-[18px]">
                      <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] mr-2 small " />
                        <h3 className="small ">Logo Design</h3>
                      </li>
                      {/* <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] mr-2 small " />
                        <h3 className="small ">Graphic Design</h3>
                      </li> */}
                      <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] mr-2 small " />
                        <h3 className="small ">Landing Page Designing</h3>
                      </li>
                      <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] small  mr-2" />
                        <h3 className="small ">Static Web Designing</h3>
                      </li>
                      <li className="flex text-[20px]">
                        <IoIosArrowForward className="mt-[6px] small  mr-2" />
                        <h3 className="small ">Custom Website Design</h3>
                      </li>
                      <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] small  mr-2" />
                        <h3 className="small ">Responsive Website Designing</h3>
                      </li>
                      <li className="flex ">
                        <IoIosArrowForward className="mt-[6px] small  mr-2" />
                        <h3 className="small ">Dynamic Website Design</h3>
                      </li>
                      
                    </ul>
                  </div>
                  <div class="go-corner" href="#">
                    <div class="go-arrow">→</div>
                  </div>
                </div>
              </div>
              {/* ============== */}
              <div className="card1 ">
                <h3 className="font-semibold card-heading text-[33px] font-Opensanfour">
                  Website Development
                </h3>
                <div>
                  <ul className="mt-4 font-serif text-[18px]">
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                      <h3 className="small ">Ecommerce Development</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">Joomla Development</h3>
                    </li>

                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">App Development</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">PHP Web Development</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">B2C Portal Development </h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">B2B Web Development</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">CMS Web Development</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">WordPress Development</h3>
                    </li>
                  </ul>
                </div>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </div>
              {/* ========================= */}
              <div className="card1 ">
                <h3 className="font-semibold card-heading text-[35px] font-Opensanfour">
                  Digital <br /> Marketing
                </h3>
                <div>
                  <ul className="mt-3  font-serif text-[18px]">
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2 " />
                      <h3 className="small">SEO Services</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">SEO portfolio</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">PPC Services</h3>
                    </li>
                    <li className="flex text-[20px]">
                      <IoIosArrowForward className="mt-[6px] mr-2 small " />
                      <h3 className="small ">SMM Services</h3>
                    </li>
                    {/* <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">Digital Marketing</h3>
                    </li> */}
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">Local SEO</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">E-Commerce SEO</h3>
                    </li>
                    <li className="flex ">
                      <IoIosArrowForward className="mt-[6px] small  mr-2" />
                      <h3 className="small ">Mobile SEO</h3>
                    </li>
                  </ul>
                </div>
                <div class="go-corner">
                  <div class="go-arrow">→</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================================== */}
        <section>
          <div className="px-16 py-10 text-center font-RobatoCondesed bg-gray-200 max-md:px-6">
            <h2 className="text-[30px] font-semibold text-center">
              Why Use Only Our Services?
            </h2>
            <p className="mt-5 text-[18px] leading-8 text-justify">
              Selecting "Honey Iconics" in Coimbatore is a decision chosen for
              leading web development, design, and digital marketing. Our track
              record of achievements proves our dedication to completing
              projects successfully. Using a customer-focused approach, we work
              together and interact honestly to prioritize our goals. Our
              foundation is confidence, which ensures on-time and stable
              delivery. Discover an in-depth solution that comes under one roof
              to simplify your digital journey. Choose our premium offerings and
              work with leading experts in Coimbatore's changing digital space.
            </p>
            <br />
            <br />
          </div>
        </section>
      </section>
      <Footer />
      <MobileSocialIcons/>

    </>
  );
};

export default AboutUs;
