import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StaticImage from "../../images/Web-development/Static-images-removebg.png";
import Ecommerceone from "../../images/e-commerce page/Ecomorce.png";
import Ecommercetwo from "../../images/e-commerce page/Catring.png";
import Ecommercethree from "../../images/e-commerce page/WhatsApp Image 2023-12-21 at 04.50.19_43acbdc6.jpg";
import Ecommercefour from "../../images/e-commerce page/enterprises.png";
import "../../css-files/development.css";
import Footer from "../../components/footer/footer";
import MobileSocialIcons from "../mobile-social-links";
import Headerone from "../../components/NavBars/header-one/headerone";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";

export default function Ecommercewebsite() {
  return (
    <>
    <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Website Designing Company in Coimbatore</title>
          <meta
            name="description"
            content="Honey Iconics is a trusted digital marketing company in Coimbatore. Our team of digital marketing experts is dedicated to helping businesses establish a strong online presence and achieve their marketing goals."
          />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
          />
        </Helmet>
      </div>
      {/* =====================static Website================== */}

      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>E-Commerce Website</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                  Searching For Best Website Design company Services In
                  coimbatore To Create A Modern Website For Your Business Or
                  Startup? Take Quick Action And Hire Honeyiconics Website
                  Development Company In Coimbatore For The Best Web Development
                  Services At Affordable Prices.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================static Website end================== */}

      {/* ==========================what is static website=========================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              E-Commerce
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Website
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              Welcome to Honeyiconics Leading Website Developement Company,
              where we take pride in being India's leading website development
              company. If you're ready to propel your business to new heights,
              we're here to help you create a powerful online presence that
              captivates your audience and drives success.
              <br />
              <br />
              Unlock the full potential of your business with India's top
              website development company. Elevate your online presence, attract
              customers, and boost revenue. Get started today with our expert
              web development services! We understand that every business is
              unique. Our website development services are tailored to your
              specific requirements, ensuring that your online presence reflects
              the essence of your brand.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* ==========================what is static website end=========================== */}

      {/* =======================why choose========================= */}
      <section>
        <div className="px-16 font-RobatoCondesed py-16 mt-10 bg-gray-100 max-md:px-6">
          <div>
            <h2 className="text-[35px] font-medium text-center uppercase">
              Our
              <span className="ml-3 mr-3 font-serif text-orange-500">
                E-Commerce Website Development
              </span>
              Services
            </h2>
          </div>

          <div className="flex mt-10 font-RobatoCondesed max-md:flex-col container ">
            <div className="w-[50%] max-md:w-full">
              <center>
                <img
                  className="object-cover w-full h-96 "
                  src={StaticImage}
                  alt="loading"
                  title=""
                />
              </center>
            </div>
            <div className="w-[50%] max-md:w-full">
              <h2 className="font-medium uppercase text-[32px]">
                Why choose E-Commerce Website ?
              </h2>
              <div className="h-1 mt-3 bg-orange-500 w-96 rounded-xl"></div>
              <p className="mt-5 leading-loose text-justify">
                Choosing us for your static website needs ensures a seamless and
                cost-effective online presence that aligns with your business
                goals. Our commitment to delivering high-quality static websites
                is rooted in the understanding that simplicity can be powerful.
                With a focus on efficiency and reliability, our expert team
                crafts static websites that load swiftly, providing visitors
                with a smooth and satisfying browsing experience. Opting for our
                services means choosing a cost-effective solution without
                compromising on quality. We prioritize simplicity and ease of
                maintenance, enabling you to effortlessly update and manage your
                content. Our static websites are not only visually appealing but
                also highly secure, mitigating potential vulnerabilities.
              </p>
              <p className="mt-5 leading-loose">
                Hosting flexibility and search engine friendliness are integral
                aspects of our approach, ensuring that your website not only
                stands out but also performs exceptionally well.
              </p>
              <div>
                <h2>CSS TODO List Design Inspiration</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========================why choose end======================== */}

      {/* =================portfolio======================= */}

      <div className="portfolio-example-section mt-5">
        <div>
          <h2 className="text-[35px] font-RobatoCondesed font-semibold text-center text-orange-500">
            Our Portfolio{" "}
          </h2>
          <p className="text-xl font-thin text-center ">
            Our Latest Work For Your Business{" "}
          </p>
        </div>
        <div className="portfolio-example-container container">
          <div className="portfolio-image-boxes">
            <div className="portfolio-imgone">
              <img src={Ecommerceone} alt="Portfolio" title="" />
            </div>
            <div className="portfolio-imgone">
              <img src={Ecommercetwo} alt="Portfolio" title="" />
            </div>
          </div>
          <div className="portfolio-image-box">
            <div className="portfolio-imgtwo">
              <img src={Ecommercethree} alt="Portfolio" title="" />
            </div>
            <div className="portfolio-imgtwo">
              <img src={Ecommercefour} alt="Portfolio" title="" />
            </div>
          </div>
        </div>
      </div>

      {/* =================portfolio end======================= */}
      <section>
        <div className="px-16 py-5 mb-10 bg-gray-100 max-md:px-6 font-RobatoCondesed">
          <div className="">
            <h2 className="text-center pt-10 font-medium mb-10 text-orange-500 text-[35px]">
              {" "}
              FAQs{" "}
            </h2>
            <div className="mb-10 space-y-4">
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900 ">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What are e-commerce websites, and why are they crucial for
                  businesses in Coimbatore?{" "}
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  E-commerce websites are online platforms that facilitate
                  buying and selling of products or services over the internet.
                  They are crucial for businesses in Coimbatore as they provide
                  a convenient and accessible way for customers to browse,
                  purchase, and interact with products or services, expanding
                  market reach and increasing sales potential.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  How does Honeyiconics approach the development of e-commerce
                  websites for businesses in Coimbatore?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Honeyiconics takes a strategic approach to developing
                  e-commerce websites for businesses in Coimbatore. We start by
                  understanding the client's business goals, target audience,
                  and industry requirements. Our team then designs and develops
                  e-commerce websites with user-friendly interfaces, secure
                  payment gateways, product catalogs, shopping carts, and order
                  management systems.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  Can Honeyiconics create custom e-commerce functionalities and
                  features for businesses in Coimbatore?{" "}
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Yes, Honeyiconics specializes in creating custom e-commerce
                  functionalities and features for businesses in Coimbatore.
                  Whether it's advanced product search filters, personalized
                  recommendations, multi-vendor marketplaces, subscription
                  services, or integration with ERP systems, we can tailor the
                  e-commerce website to meet the specific needs of our clients.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  How does Honeyiconics ensure that e-commerce websites
                  developed for businesses in Coimbatore are visually appealing
                  and user-friendly?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  We prioritize visual appeal and user-friendliness in
                  e-commerce website development for businesses in Coimbatore.
                  Our team designs intuitive navigation, attractive product
                  displays, clear calls-to-action, mobile responsiveness, and
                  seamless checkout processes to enhance user experiences and
                  drive conversions.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  Can Honeyiconics integrate secure payment gateways and SSL
                  certificates into e-commerce websites for businesses in
                  Coimbatore?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Absolutely, Honeyiconics can integrate secure payment gateways
                  and SSL certificates into e-commerce websites for businesses
                  in Coimbatore. We work with trusted payment providers and
                  ensure data encryption, PCI compliance, and secure
                  transactions to build customer trust and protect sensitive
                  information.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
      <MobileSocialIcons />
      <Footer />
    </>
  );
}
