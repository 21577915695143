import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StaticImage from "../../images/Web-development/Static-images-removebg.png";
import Portfolioone from "../../images/static-page/inddecoreinteriors.com_.png";
import Portfoliotwo from "../../images/static-page/WhatsApp Image 2023-12-21 at 04.50.18_0f7f5bce.jpg";
import Portfoliothree from "../../images/static-page/abtoursandtravels.in_.png";
import Portfoliofour from "../../images/static-page/devdigitallandsurveyor.com_index.html.png";
import "../../css-files/development.css";
import Footer from "../../components/footer/footer";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";
import Headerone from "../../components/NavBars/header-one/headerone";
import MobileSocialIcons from "../mobile-social-links";

export default function Staticwebsite() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Best Website Development Company in Coimbatore | Website Development
            Company in Coimbatore{" "}
          </title>
          <meta
            name="description"
            content=" Explore our static website services tailored for businesses in Coimbatore. Streamline your online presence with elegant and efficient static websites.
            "
          />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
          />
        </Helmet>
      </div>
      {/* =====================static Website================== */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Static Website</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                  Boost your online visibility with our expert static website
                  development services in Coimbatore. Creating beautiful and
                  functional static websites that fit the objectives and
                  requirements of your brand.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================static Website end================== */}

      {/* ==========================what is static website=========================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              Static
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Website
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              Static websites are primarily used to showcase information. They
              are ideal for businesses or individuals who need an online
              presence to display their services, portfolios, or basic
              information. Static websites are easy to create and maintain,
              requiring minimal technical knowledge.
              <br />
              <br />
              They load quickly and are reliable, making them suitable for sites
              with low traffic or those not requiring frequent updates.
              Additionally, static websites are cost-effective, making them a
              practical choice for small businesses or startups looking to
              establish an online presence without investing in complex <Link className="text-orange-500" to={"/website-development-company-in-coimbatore"}>web
              development.</Link>
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* ==========================what is static website end=========================== */}

      {/* =======================why choose========================= */}
      <section>
        <div className="px-16 font-RobatoCondesed py-16 mt-10 bg-gray-100 max-md:px-6">
          <div>
            <h2 className="text-[35px] font-medium text-center uppercase">
              Our
              <span className="ml-3 mr-3 font-serif text-orange-500">
                Static Website Development
              </span>
              <br />
              Services
            </h2>
          </div>

          <div className="flex mt-10 font-RobatoCondesed max-md:flex-col container ">
            <div className="w-[50%] max-md:w-full">
              <center>
                <img
                  className="object-cover w-full h-96 "
                  src={StaticImage}
                  alt="loading"
                  title="Static-Image"
                />
              </center>
            </div>
            <div className="w-[50%] max-md:w-full">
              <h2 className="font-medium uppercase text-[32px]">
                Why choose Static Website ?
              </h2>
              <div className="h-1 mt-3 bg-orange-500 w-75 rounded-xl"></div>
              <p className="mt-5 leading-loose text-justify">
                Choosing us for your static website needs ensures a seamless and
                cost-effective online presence that aligns with your business
                goals. Our commitment to delivering high-quality static websites
                is rooted in the understanding that simplicity can be powerful.
                With a focus on efficiency and reliability, our expert team
                crafts static websites that load swiftly, providing visitors
                with a smooth and satisfying browsing experience. Opting for our
                services means choosing a cost-effective solution without
                compromising on quality. We prioritize simplicity and ease of
                maintenance, enabling you to effortlessly update and manage your
                content. Our static websites are not only visually appealing but
                also highly secure, mitigating potential vulnerabilities.
              </p>
              <p className="mt-5 leading-loose">
                Hosting flexibility and search engine friendliness are integral
                aspects of our approach, ensuring that your website not only
                stands out but also performs exceptionally well.
              </p>
              <div>
                <h2>CSS TODO List Design Inspiration</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========================why choose end======================== */}

      {/* =================portfolio======================= */}

      <div className="portfolio-example-section mt-5">
        <div>
          <h2 className="text-[35px] font-RobatoCondesed font-semibold text-center text-orange-500">
            Our Portfolio{" "}
          </h2>
          <p className="text-xl font-thin text-center ">
            Our Latest Work For Your Business{" "}
          </p>
        </div>
        <div className="portfolio-example-container container">
          <div className="portfolio-image-boxes">
            <div className="portfolio-imgone">
              <img src={Portfolioone} alt="Portfolio" title="static-website" />
            </div>
            <div className="portfolio-imgone">
              <img src={Portfoliotwo} alt="Portfolio" title="static-website" />
            </div>
          </div>
          <div className="portfolio-image-box">
            <div className="portfolio-imgtwo">
              <img
                src={Portfoliothree}
                alt="Portfolio"
                title="static-website"
              />
            </div>
            <div className="portfolio-imgtwo">
              <img src={Portfoliofour} alt="Portfolio" title="static-website" />
            </div>
          </div>
        </div>
      </div>

      {/* =================portfolio end======================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What are static websites, and why are they a great choice
                    for businesses in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Static websites are web pages with fixed content that
                    remains the same for every visitor. They are a great choice
                    for businesses in Coimbatore looking for a simple,
                    cost-effective online presence without dynamic functionality
                    requirements like e-commerce. Static websites load quickly,
                    are easy to maintain, and are suitable for showcasing
                    information such as company profiles, portfolios, and
                    services.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that static websites developed
                    for businesses in Coimbatore are visually appealing and
                    user-friendly?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics focuses on clean and professional designs for
                    static websites developed for businesses in Coimbatore. We
                    prioritize intuitive navigation, clear layout, compelling
                    visuals, and user-friendly interfaces to create engaging and
                    easy-to-use static websites that leave a lasting impression
                    on visitors.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can Honeyiconics incorporate custom designs and branding
                    elements into static websites for businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, Honeyiconics specializes in incorporating custom
                    designs and branding elements into static websites for
                    businesses in Coimbatore. We work closely with clients to
                    understand their brand identity, preferences, and vision,
                    and then create bespoke designs that reflect their unique
                    style and messaging effectively.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that static websites developed
                    for businesses in Coimbatore are optimized for search
                    engines (SEO)?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    We follow SEO best practices during the development of
                    static websites for businesses in Coimbatore. This includes
                    optimizing meta tags, headers, and URLs, creating
                    SEO-friendly content structures, implementing schema markup,
                    optimizing site speed and performance, and ensuring
                    mobile-friendliness.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}
      <Footer />
      <MobileSocialIcons />
    </>
  );
}
