import React, { useState } from 'react';
import { FaAddressBook, FaPhone, FaWhatsapp, FaSkype, FaEnvelope } from 'react-icons/fa';

import '../css-files/development.css';

const MobileSocialIcons = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <section className="mobile-social-icons">
            <div className="mobile-social-container">
                <div className={`mobile-social-toggler ${isOpen ? 'ro45' : ''}`} onClick={toggleMenu}>
                    <span>
                        <FaAddressBook className="fa-address-book" />
                    </span>
                </div>
                <div className={`mobile-toggler-menus ${isOpen ? 'mobResponsive' : ''}`}>
                    <div className="mobile-menus-container">
                        <span style={{ '--i': 1, '--bac': 'rgb(0, 81, 255)' }}>
                            <a href="tel:+916379453263" rel="noreferrer" target="_blank" className="social-links">
                                <FaPhone />
                            </a>
                        </span>
                        <span style={{ '--i': 2, '--bac': 'green' }}>
                            <a href="https://wa.me/+916379453263" rel="noreferrer" target="_blank" className="social-links">
                                <FaWhatsapp />
                            </a>
                        </span>
                        {/* <span style={{ '--i': 3, '--bac': 'rgb(74, 204, 255)' }}>
                            <a href="#" target="_blank" className="social-links">
                                <FaSkype />
                            </a>
                        </span> */}
                        <span style={{ '--i': 4, '--bac': 'rgb(0, 81, 255)' }}>
                            <a href="mailto:Arulmuniyappan@honeyiconics.in" rel="noreferrer" target="_blank" className="social-links">
                                <FaEnvelope />
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MobileSocialIcons;
