import React from "react";
import { Helmet } from "react-helmet";
import { IoIosArrowForward } from "react-icons/io";
import logoone from "../../images/Logo/logo1.jpg";
import logotwo from "../../images/Logo/logo2.jpg";
import logothree from "../../images/Logo/logo3.jpg";
import logofour from "../../images/Logo/logo4.jpg";
import logofive from "../../images/Logo/logo5.jpg";
import logosix from "../../images/Logo/logo6.jpg";

import { Link } from "react-router-dom";
import "../../css-files/development.css";
import Footer from "../../components/footer/footer";
import Headerone from "../../components/NavBars/header-one/headerone";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "../mobile-social-links";

export default function Logopage() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Logo Design & Branding Services in Coimbatore </title>
          <meta
            name="description"
            content="Transform your brand's identity with expert logo design and branding services in Coimbatore. Our strategic approach ensures impactful visual identities that resonate with your audience."
          />
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/logo-design-branding-services"
          />
        </Helmet>
      </div>
      {/* =====================logo================= */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Logo & Branding</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                  Logo design is the art of creating a visually impactful symbol
                  that represents a company's identity and values. At the same
                  time, branding is the strategic process of shaping and
                  promoting that identity to create a unique and memorable
                  impression in the minds of consumers.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================logo end================== */}

      {/* ==================logo definition=================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              Logo &
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Branding
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              Boost the visibility of your business by working with Honey
              Iconics, the top branding company in Coimbatore that provides
              professional <Link className="text-orange-500" to={"/logo-design-branding-services"}>logo design</Link> and branding services. Our team
              specializes in creating eye-catching logos that capture the spirit
              of your business and maintain consistency and memory throughout
              all touchpoints. We can work with you to develop your brand
              identity, messaging, and visual components through smart branding
              so that your audience will remember you.
              <br />
              <br />
              With a focus on presenting the story of your brand and a sharp eye
              for design, we'll work directly with you to realize your vision.
              You can count on us to develop an integrated brand experience that
              connects with your target audience and makes you stand out from
              your competitors.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* =====================logo definition end========================== */}

      {/* ====================================================== */}
      <section className="px-16 py-6 max-md:px-4 dark:bg-gray-800">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-center  text-[35px] font-Opensanfour">
                Logo Design
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ============== */}
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
                Development of Brand Identity
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Creating a powerful brand involves more than developing a
                      logo. The key is to develop a single brand that expresses
                      your values and identity. To ensure the accuracy of all
                      interactions, our team of experts will assist you in
                      creating a full brand identity that includes your logo,
                      color scheme, design, pictures, and messaging.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ========================= */}
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
                brand strategy
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Increase the visibility of your brand with strategic
                      planning and customized branding. Our creative and
                      strategic logo design services combine to create
                      eye-catching identities that connect with the people you
                      want to reach. Put trust in our experience to execute your
                      brand's vision with style and accuracy, differentiating
                      you from other businesses.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================================================= */}

      {/* =================portfolio======================= */}

      {/* <section className="py-6 ">
                <div className="container flex flex-col justify-center p-4 mx-auto">
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i className="fas fa-hand-holding-heart"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        E-Commerce Excellence
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i class="fa-solid fa-handshake"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Manufacturing Mastery
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i class="fa-solid fa-graduation-cap"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        Educational Elevation
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i class="fa-solid fa-suitcase"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Financial Finesse
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i class="fa-solid fa-gavel"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        Hospitality Harmony
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-orange-500">
                        <i class="fa-solid fa-building"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Tech Savvy Solutions
                      </p>
                    </div>

                   
                  </div>
                </div>
              </section> */}

      <section className="py-6 container ">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={logoone} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={logotwo} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
              <img src={logothree} alt="logo" title="logo-image" />
            </div>
          </div>
        </div>
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={logofour} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={logofive} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
              <img src={logosix} alt="logo" title="logo-image" />
            </div>
          </div>
        </div>
        {/* <div className="container flex flex-col justify-center p-4 mx-auto">
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
        <img src={logofour} alt="" />
      </div>

      <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
      <img src={logofive} alt="" />
      </div>

      <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
      <img src={logosix} alt="" />
      </div>
    </div>
  </div> */}
      </section>

      {/* =================portfolio end======================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What makes Honeyiconics stand out as the best logo designing
                    company in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Honeyiconics stands out for its creativity, expertise, and
                    attention to detail in logo designing. We focus on creating
                    unique and memorable logos that effectively represent the
                    brand identity and values of businesses in Coimbatore.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What types of logos does Honeyiconics specialize in
                    designing for businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics specializes in designing a variety of logos,
                    including wordmarks, lettermarks, emblems, symbols, abstract
                    marks, and combination marks, tailored to meet the specific
                    needs and preferences of businesses in Coimbatore.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that logos designed for
                    businesses in Coimbatore are visually appealing and
                    representative of their brand identity?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics takes a strategic approach to logo design,
                    starting with a thorough understanding of the client's
                    brand, target audience, and industry. We then focus on
                    creating logos with appealing typography, color schemes,
                    imagery, and symbolism that effectively convey the brand's
                    message and values.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can Honeyiconics provide multiple design concepts and
                    revisions for logos to businesses in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, Honeyiconics offers multiple design concepts and
                    revisions for logos to businesses in Coimbatore. We
                    collaborate closely with clients to gather feedback, refine
                    designs, and ensure that the final logo meets their
                    expectations and aligns with their brand identity.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that logos designed for
                    businesses in Coimbatore are versatile and suitable for
                    various applications?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics designs logos that are versatile and suitable
                    for various applications, including print, digital media,
                    signage, merchandise, and more. We consider scalability,
                    color variations, readability, and adaptability to different
                    backgrounds to ensure that logos maintain their impact
                    across different platforms.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}
      <Footer />
      <MobileSocialIcons />
    </>
  );
}
