import React from "react";
import Beautyparlour from "../images/workflow/bueatyparlor.png";

import FurenitureOne from "../images/workflow/Furniture-one.png";

import Eventmanagement from "../images/workflow/Managment.png";

import SareeCollection from "../images/workflow/saree-collection.png";

import Music from "../images/workflow/Music.png";

import Purifier from "../images/workflow/waterpurifier.png";

import Health from "../images/workflow/HealthCare.png";

import Catring from "../images/workflow/Catring.png";

import Ecommerces from "../images/workflow/Ecomorce.png";
import { Helmet } from "react-helmet";
import Acadamy from "../images/workflow/acadamy.png";
import { Link } from "react-router-dom";
import Headerone from './../components/NavBars/header-one/headerone';
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import Footer from "../components/footer/footer";
import MobileSocialIcons from "./mobile-social-links";

const OurWorkFlow = () => {
  return (
    <div>
       <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Explore Our Branding Portfolio - Coimbatore</title>
          <meta
            name="description"
            content=" Discover our impressive portfolio showcasing expert logo design and branding projects in Coimbatore. See how we elevate brands with creative and impactful solutions."
          />
          <link
            rel="canonical"
            href="https://honeyiconics.com/home
"
          />
        </Helmet>
      </div>
      <section>
        <div>
          <section class="wrapper mb-20 pb-10">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 max-lg:flex-col">
              <div className="w-[100%] text-[3.8rem] pt-20 max-lg:w-full md:px-16 max-lg:pl-none max-md:px-6  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  max-sm:text-[2rem] text-white "
                >
                  <span>Our Portfolio</span>
                </h1>
                <p className="mt-6 md:px-18 max-sm:text-[15px]  text-[18px] font-RobatoCondesed text-center text-white ">
                  Searching For Best Website Development Services In India To
                  Create A Modern Website For Your Business Or Startup? Take
                  Quick Action And Hire HoneyIconics A Leading Website
                  Development Company In India For The Best Website Development
                  Services At Affordable Prices.
                </p>

                <center>
                  <Link to={"/contact"}>
                    {" "}
                    <button class="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us{" "}
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* =================================== */}
      <section className="py-6 ">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-20 lg:grid-cols-2 sm:grid-cols-2">
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Acadamy}
                alt="Acadamy"
                title="acadamy-portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Health}
                alt="Health"
                title="thendral-care-portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={FurenitureOne}
                alt="loading"
                title="furniture-portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Eventmanagement}
                alt="loading"
                title="event-management-portfolio"
              />
            </div>{" "}
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Eventmanagement}
                title="event-management-portfolio"
                alt="loading"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={SareeCollection}
                title="boutique-portfolio"
                alt="loading"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Music}
                alt="loading"
                title="music-website portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Purifier}
                alt="loading"
                title="purifier-website portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Catring}
                alt="loading"
                title="catering-portfolio"
              />
            </div>
            <div className="w-full h-full ">
              <img
                className="object-contain "
                src={Ecommerces}
                alt="loading"
                title="e-commerce-portfolio"
              />
            </div>
            <div className="relative w-full h-full">
              <img
                className="object-contain dark:bg-gray-500 "
                src={Beautyparlour}
                title="beauty-parlour-portfolio"
                alt="loading"
              />

              <div className="absolute w-full "></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <MobileSocialIcons/>
    </div>
  );
};

export default OurWorkFlow;
