import React from "react";
import { Helmet } from "react-helmet";
import Checkbox from "../images/checkbox (1).png";
import meeting from "../images/Home/meeting.jpg";
import Tick from "../images/checkbox (1).png";

import Responsive from "../images/Home/Image-Sec-Two.jpg";
import requirement from "../images/google/your-requirement.png";
import estimation from "../images/google/satisfied-delivery.png";
import visit from "../images/google/Schedule-visit.png";

import { Link } from "react-router-dom";
import "../css-files/development.css";
import "../css-files/google.css";
import Footer from "../components/footer/footer";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "./mobile-social-links";

export default function Googlepage() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Top Google Advertising Company in Coimbatore | Boost Your Online
            Presence
          </title>
          <meta
            name="description"
            content="Looking for the best Google advertising company in Coimbatore? Our expert team maximizes your online visibility with targeted ads. Contact us for effective PPC campaigns!"
          />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
          />
        </Helmet>
      </div>
      {/* =====================logo================= */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Google Adwords</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                  Are you searching for the best Google advertising company in
                  Coimbatore? Look no further! Our company stands out as a
                  leader in digital marketing, specializing in Google
                  advertising strategies that drive results.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================logo end================== */}

      {/* ==================google adewords definition=================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              Google
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Adwords
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              What sets us apart as the best google advertising company in
              Coimbatore is our tailored approach to each client. We understand
              that every business is unique, so we create customized Google ad
              campaigns that align with your goals and target audience. Whether
              you're looking to increase website traffic, generate leads, or
              boost sales, our team has the expertise to make it happen.
              <br />
              <br />
              Our proven track record speaks for itself. We have helped numerous
              businesses in Coimbatore achieve success through our innovative
              Google advertising strategies. From keyword research and ad
              creation to optimization and monitoring, we handle every aspect of
              your Google Ads campaign with precision and care.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* ====================google adwords definition end========================== */}

      {/* ============================ card page======================= */}
      <div className="work-project-section mt-4">
        <h2>How it Works</h2>
        <p> Maximize Your Reach with Google Search Ads</p>
        <div className="work-project-container container">
          <div className="work-img-box">
            <div className="work-img">
              <div className="work-imgone">
                <img src={requirement} alt="requirement" title="requirement" />
                <p>Expertise</p>
              </div>
              <div className="work-imgone">
                <img src={requirement} alt="requirement" title="requirement" />
                <p>Customization</p>
              </div>
              <div className="work-imgone">
                <img src={estimation} alt="Estimation" title="estimation" />
                <p>Improved ROI</p>
              </div>
              <div className="work-imgone">
                <img src={visit} alt="Schedule" title="visit" />
                <p>Transparency</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-between gap-10 mt-10 max-md:flex-col">
        <div className="w-[50%] max-md:w-full p-6 bg-white border-2 border-orange-500 hover:shadow-md hover:shadow-gray-400">
          <div>
            <ul>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-3 ml-2 text-lg font-normal">
                  Create Your Web Identity
                </span>
              </li>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  Easy To Change And Update
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  Be Accessible To New Customers
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-[50%] max-md:w-full border-orange-500 p-6 bg-white border-2 hover:shadow-md hover:shadow-gray-400">
          <div>
            <ul>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-3 ml-2 text-lg font-normal">
                  Global Presence
                </span>
              </li>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  Credibility and Trust
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  24/7 Accessibility
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-[50%] max-md:w-full p-6 border-orange-500 bg-white border-2 hover:shadow-md hover:shadow-gray-400">
          <div>
            <ul>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-3 ml-2 text-lg font-normal">
                  Marketing and Visibility
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  Showcasing Products or Services
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" title="" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  Adaptability to Changing Trends
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      {/* ============================ card page end======================= */}

      {/* =========================== features=========================== */}

      <section>
        <section>
          <div className="flex h-auto px-10 mt-10 mb-5 bg-white max-md:flex-col">
            <div className=" w-[100%] h-[400px]">
              <img
                className="object-contain w-full h-full"
                src={meeting}
                alt="Features"
                title=""
              />
            </div>
            <div className="w-[100%] h-auto">
              <h2 className="text-[23px] text-orange-500  font-RobatoCondesed  ">
                why you need Google ads:
              </h2>
              <p className="mt-5 text-justify font-RobatoCondesed">
                In today's digital age, Google Ads have become an indispensable
                tool for businesses looking to expand their online presence and
                reach their target audience effectively.
              </p>

              <div className="mt-5">
                <ul>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">
                      Reach Your Target Audience
                    </p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Immediate Visibility</p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">
                      Increase Brand Awareness
                    </p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Controlled Budget </p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Trackable Results </p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Targeted Keywords </p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Flexible Ad Formats</p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Compete Effectively</p>
                  </li>
                  <li className="flex ">
                    <img
                      className="mr-2 h-7 w-7"
                      src={Checkbox}
                      alt="Checkbox"
                      title=""
                    />
                    <p className="font-RobatoCondesed">Drive Conversions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* ============================features end ============================= */}

      {/* <section className=" w-full px-10 p-5 mb-5  max-sm:px-0 flex-column ">
        <div className="w-full  h-auto  btn-hover.color-2   shadow-2xl bg-slate-900 text-white rounded-3xl  ">
          <ul className="flex gap-4 p-5 text-left ms-5">
            <li>
              <div className="">
                <div className="">
                  <span className="text-[30px] font-medium max-sm:text-[15px]">
                    <h2>
                      Target, Engage, Convert
                      <br />
                      Stay Ahead in the Digital Race – Opt for Google Ads Now!
                    </h2>
                  </span>
                </div>
              </div>
            </li>
            <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[100%] ms-20 text-3xl mt-5 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
          </ul>
        </div>
      </section> */}

      {/* =================services======================= */}

      <section>
        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Search Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    Search ads appear at the top of Google search results when
                    users enter relevant search queries.{" "}
                  </li>
                  <li>
                    hey are text-based ads with a headline, description, and
                    URL, targeting specific keywords related to your business.{" "}
                  </li>
                  <li>
                    Search ads are effective for driving immediate traffic to
                    your website and capturing users actively searching for
                    products or services.
                  </li>
                </p>
              </div>{" "}
            </div>
          </div>
        </div>

        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Display Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    {" "}
                    Display ads are visual ads that appear on websites within
                    the Google Display Network, which includes millions of
                    websites, apps, and videos.
                  </li>
                  <li>
                    They can be static images, animated graphics, or rich media
                    formats, making them visually appealing to attract users'
                    attention.
                  </li>
                  <li>
                    {" "}
                    Display ads are great for increasing brand awareness,
                    reaching a broader audience, and driving engagement through
                    compelling visuals.
                  </li>
                </p>
              </div>{" "}
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Video Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    Video ads are displayed on YouTube and across the Google
                    Display Network, targeting users based on their interests,
                    demographics, and online behavior.
                  </li>
                  <br />
                  <li>
                    They can be in-stream ads that play before, during, or after
                    YouTube videos, or display ads within YouTube search results
                    and video watch pages.
                  </li>
                  <br />
                  <li>
                    {" "}
                    Video ads are effective for storytelling, showcasing
                    products or services, and engaging users with video content.
                  </li>
                </p>
              </div>{" "}
            </div>
          </div>
        </div>

        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Shopping Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    Shopping ads, also known as Product Listing Ads (PLAs),
                    display product images, prices, and store information
                    directly on Google search results.
                    <li>
                      They are ideal for e-commerce businesses, allowing users
                      to see product details and prices before clicking on the
                      ad.
                    </li>
                    <li>
                      Shopping ads are highly targeted, based on product data
                      provided through Google Merchant Center, and can drive
                      qualified traffic to your online store.
                    </li>
                  </li>
                </p>
              </div>{" "}
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  App Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    App ads promote mobile apps across Google Search, Google
                    Play, YouTube, and the Google Display Network to increase
                    app installs and engagement.
                  </li>
                  <li>
                    They can be text-based ads, image ads, or video ads that
                    encourage users to download or engage with your app.
                  </li>
                  <li>
                    App ads are effective for app developers and businesses
                    looking to expand their app's user base and drive
                    app-related actions.
                  </li>
                </p>
              </div>{" "}
            </div>
          </div>
        </div>

        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-5">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto ">
                <h2 className="text-[25px] mt-5 font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Local Ads:
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  <li>
                    Local ads, such as Google My Business ads and Local Search
                    ads, target users based on their location and proximity to
                    your business.
                  </li>
                  <li>
                    They appear in Google Maps, local search results, and Google
                    Search, providing users with information about your
                    business, including contact details, reviews, and
                    directions.
                  </li>
                  <li>
                    Local ads are essential for brick-and-mortar businesses to
                    drive foot traffic, increase store visits, and connect with
                    local customers.
                  </li>
                </p>
              </div>{" "}
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =================services end======================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What does Honey Iconics specialize in ?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Honey Iconics is a leading website development company based
                    in Coimbatore. We specialize in a range of digital services,
                    including website development, SEO (search engine
                    optimization), logo design, etc.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can you tell us more about your website development
                    services?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Certainly! We take pride in crafting custom websites
                    tailored to the unique needs of our clients. Our team of
                    experienced developers ensures that your website is not only
                    visually appealing but also highly functional and
                    user-friendly.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What is SEO, and how does Honey Iconics help businesses with
                    it?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    SEO, or Search Engine Optimization, is the process of
                    optimizing your website to rank higher in search engine
                    results. At Honey Iconics, we provide SEO services that
                    improve your website's visibility, driving organic traffic
                    and helping you reach your target audience effectively.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Do you offer logo design services as well?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, we do! Our skilled designers create memorable and
                    impactful logos that represent your brand's identity. A
                    well-designed logo can leave a lasting impression on your
                    audience, and we're here to ensure it's a positive one.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}
      <Footer />
      <MobileSocialIcons />
    </>
  );
}
