import React from "react";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import Footer from "../components/footer/footer";
import MobileSocialIcons from "./mobile-social-links";

export default function Privacypage() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy Policy | Honey Iconics</title>
          <meta
            name="description"
            content="Learn how Honey Iconics protects your privacy with our transparent Privacy Policy. Understand how we collect, use, and protect your personal information to ensure a secure online experience."
          />
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/logo-design-branding-services"
          />
        </Helmet>
      </div>
      {/* =====================logo================= */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Privacy Policy</span>
                </h1>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================logo end================== */}

      {/* ==================logo definition=================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              1.What Information
              <span className="font-serif mr-3 ml-3 text-orange-500 link-underline-danger ">
                We Collect:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                If you engage with our website, we may gather your personal
                information, including your name, email address, and contact
                details.
              </li>
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                Your IP address, browser type, and browsing history are the
                other details we collect automatically.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              2. How We Use
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Your Information:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                We use your personal information to respond to your inquiries
                and provide you with requested services.
              </li>
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                We may also send you marketing communications, which you can opt
                out of at any time.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              3.Data
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Sharing:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                We do not sell your personal information to third parties.
              </li>
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                It is only shared with trusted service providers who assist us
                in operating our website.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              4.Data
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Security:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                We take measures to protect your personal information, but
                please understand that no method of transmission over the
                Internet is completely secure.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              5.Your
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Choices:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                You have the right to access, correct, or delete your personal
                information. You can also opt out of marketing communications.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              6.Changes to
              <span className="font-serif mr-3 ml-3 text-orange-500">
                this Policy:
              </span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                We may update this policy to reflect changes in our practices.
                We'll notify you of any significant changes.{" "}
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6 mt-10 mb-10">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              7.Contact
              <span className="font-serif mr-3 ml-3 text-orange-500">Us:</span>
            </h2>
            <ul className="list-disc pl-6">
              <li className="text-[18px]  leading-loose text-justify mt-3 max-sm:text-justify">
                If you have any questions or concerns about this policy, please
                contact us at +91-6379453263.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* =====================logo definition end========================== */}

      <Footer />
      <MobileSocialIcons />
    </>
  );
}
