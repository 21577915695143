import React from "react";
import BlogComponent from "../components/Blogcomponent/blogcompo";
import Image from "../images/20230903222709_[fpdl.in]_developer-working-with-laptop_24640-79628_large.jpg";
import Footer from "../components/footer/footer";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "./mobile-social-links";
import { Link } from "react-router-dom";
const Blog = () => {
  return (
    <>
 <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <section>
        <div>
          <section className="wrapper mb-10 pb-10">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 max-lg:flex-col">
              <div className="w-[100%] text-[3.8rem] pt-20 max-lg:w-full md:px-16 max-lg:pl-none max-md:px-6  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  max-sm:text-[2rem] text-white "
                >
                  <span>Our Blog</span>
                </h1>
                <p className="mt-6 md:px-18 max-sm:text-[15px]  text-[18px] font-RobatoCondesed text-center text-white ">
                  Searching For Best Website Development Services In India To
                  Create A Modern Website For Your Business Or Startup? Take
                  Quick Action And Hire HoneyIconics A Leading Website
                  Development Company In India For The Best Website Development
                  Services At Affordable Prices.
                </p>

                <center>
                  <Link to={"/contact"}>
                    {" "}
                    <button class="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us{" "}
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>

        {/* ======================================== */}

        <section className="py-6 ">
          <div className="flex pl-5 mb-5"><div className="h-[30px] mt-3 bg-orange-600 w-[3px] mr-2 rounded-2xl"></div> <h2 className=" text-[32px] uppercase font-semibold"> Blog details</h2></div>
          <div className="container flex flex-col justify-center p-4 mx-auto">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
              <BlogComponent
                img={Image}
                heading=" Boost Traffic & Sales with Technox: Leading SEO Company in Coimbatore"
                para="Let's first define SEO so that everyone is on the same page. The practise of optimising your website to increase its visibility on search engines..."
              />

              <BlogComponent
                img={Image}
                heading="Take your Ecommerce Business to the Next Level with Technox"
                para="Are you sick and tired of your internet business underperforming your expectations? Do you feel as though you are unable to move forward... "
              />

              <BlogComponent
                img={Image}
                heading="Take your Ecommerce Business to the Next Level with Technox"
                para="Looking to expand your company online as a business owner? Alternately, what if you're a budding businessperson seeking...
                "
              />

              <BlogComponent
                img={Image}
                heading="  Why Pick the Best Coimbatore Website Development Company for Your Online Success?"
                para="Do you want your company to have a great internet presence? Do you want to boost sales by luring in additional clients? ..."
              />

              <BlogComponent
                img={Image}
                heading="  Why Pick the Best Coimbatore Website Development Company for Your Online Success?"
                para="Do you want your company to have a great internet presence? Do you want to boost sales by luring in additional clients? ..."
              />

              <BlogComponent
                img={Image}
                heading="  Why Pick the Best Coimbatore Website Development Company for Your Online Success?"
                para="Do you want your company to have a great internet presence? Do you want to boost sales by luring in additional clients? ..."
              />

           
            </div>
          </div>
        </section>
      </section>
      <Footer />
      <MobileSocialIcons/>

    </>
  );
};

export default Blog;
