import React from "react";
import Headerone from "../../components/NavBars/header-one/headerone";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";
import Footer from "../../components/footer/footer";
import MobileSocialIcons from "../mobile-social-links";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import "../../css-files/webdesign-hidden.css";
import one from "../../images/hidden/web-design/one.png"
import two from "../../images/hidden/web-design/two.png"
import three from "../../images/hidden/web-design/three.png"
import four from "../../images/hidden/web-design/four.png"
import five from "../../images/hidden/web-design/five.png"
import six from "../../images/hidden/web-design/six.png"

const WebsiteTrichy = () => {
  return (
    <>
 <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <section>
          <div>
            <section className="wrapper pb-10 mb-20 ">
              <div id="stars1"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>

              <div className="flex pt-10 mb-10 max-lg:flex-col">
                <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                  <h1
                    id="title"
                    className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                  >
                    <span>Website Design Company in Trichy</span>
                  </h1>
                  <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                    Searching For Best Website Design company Services In
                    coimbatore To Create A Modern Website For Your Business Or
                    Startup? Take Quick Action And Hire Honeyiconics Website
                    Development Company In Coimbatore For The Best Web
                    Development Services At Affordable Prices.
                  </p>

                  <center className="">
                    <Link to={"/contact"}>
                      {" "}
                      <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                        <span className=" text-[18px] font-medium">
                          Contact Us
                        </span>
                      </button>{" "}
                    </Link>
                  </center>
                </div>
              </div>
            </section>
          </div>

        {/* ======================================== */}
         {/* =========================definition====================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
            Join Our Team of 
              <span className="font-serif mr-3 ml-3 text-orange-500">
              Talented Developers at Honeyiconics!
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
            Are you a passionate developer looking for an exciting opportunity to work on cutting-edge projects and collaborate with a team of like-minded individuals? Look no further than Honeyiconics! We are always on the lookout for top-notch developers who are eager to innovate, learn, and grow with us.
              <br />
              <br />
              We are committed to your professional development and growth. Whether it's through hands-on projects, training programs, or access to the latest tools and technologies, we provide the resources you need to thrive in your career.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>

      {/* =========================definition end=========================== */}

         {/* ========================services in website development==================  */}
         <section className=" mb-5 mt-5 px-5">
         <div className="h-auto w-[full]  max-md:px-5 text-justify mb-8 px-12 max-sm:px-4 mt-10">
                <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
                Best Website Design & Development
                {" "} 
                  <span className="text-[32px] text-orange-500 font-serif">
                  Company in Madurai{" "}
                  </span>{" "}
                </h2>
                <p className="w-[90%] font-RobatoCondesed max-sm:w-full text-center px-10 max-sm:text-justify max-md:px-0 justify-center   ">
                  Through strategic experience and perfect execution, Honey
                  Iconics shapes web development success, ensuring that your
                  online presence crosses expectations and provides amazing
                  results.
                </p>
              </div>
          <div className="service-page-section">
            <div className="service-page-container container ">
              <div className="service-page-box">
                <div className="service-img">
                  <div className="service-imgone ps-2">
                    <img src={one} alt="loading" />
                    <br />
                    <h2>Static website</h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>
                  </div>
                  <div className="service-imgone">
                    <img src={two} alt="loading" />
                    <br />
                    <h2>dynamic website</h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>

                  </div>
                  <div className="service-imgone">
                    <img src={three} alt="loading" />
                    <br />
                    <h2>e-commerce website</h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>

                  </div>
                  <div className="service-imgone">
                    <img src={four} alt="loading" />
                    <br />
                    <h2>website development</h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>

                  </div>
                  <div className="service-imgone">
                    <img src={five} alt="loading" />
                    <br />
                    <h2>Website redesign </h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>

                  </div>
                  <div className="service-imgone">
                    <img src={six} alt="loading" />
                    <br />
                    <h2>CMS development</h2>
                    <hr />
                    <p>Benefit the society where went operate. Success for website everyone.</p>

                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========================services in website development end==================  */}
        {/* <div className="process-page-section px-16">
          <div className="process-container">
            <div className="process-box">
              <div className="process-img">
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos iure aut sed eum aliquam praesentium quia vel facere, sunt, est maiores nesciunt modi fugit placeat. Accusantium, adipisci! Aliquam, illum esse.</p>
                </div>
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="process-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                
              </div>
            </div>
          </div>
        </div> */}

<div className="flexbox mt-20 px-16">
<div className="h-auto w-[full]  max-md:px-5 text-justify mb-8 px-12 max-sm:px-4 mt-10">
                <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
               Website designing 
                {" "} 
                  <span className="text-[32px] text-orange-500 font-serif">
                  Process{" "}
                  </span>{" "}
                </h2>
                <p className="w-[90%] font-RobatoCondesed max-sm:w-full text-center px-10 max-sm:text-justify max-md:px-0 justify-center   ">
                  Through strategic experience and perfect execution, Honey
                  Iconics shapes web development success, ensuring that your
                  online presence crosses expectations and provides amazing
                  results.
                </p>
              </div>
<div className="flexcard flexcardOrange">
            <div className="flexcardNumber flexcardNumberOrange">01</div>
            <div className="flexs flex flexcardTitle">Requirements</div>
            <div className="flexs flex flexcardText text-[18px]  leading-loose">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae,
                temporibus consectetur? Iure id nam fuga asperiores repellat accusantium exercitationem nemo?</div>
     
        </div> <div className="flexcard flexcardOrange">
            <div className="flexcardNumber flexcardNumberOrange">02</div>
            <div className="flexs flex flexcardTitle">UI / UX</div>
            <div className="flexs flex flexcardText text-[18px]  leading-loose">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae,
                temporibus consectetur? Iure id nam fuga asperiores repellat accusantium exercitationem nemo?</div>
           
        </div> <div className="flexcard flexcardOrange">
            <div className="flexcardNumber flexcardNumberOrange">03</div>
            <div className="flexs flex flexcardTitle">Development</div>
            <div className="flexs flex flexcardText text-[18px]  leading-loose">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae,
                temporibus consectetur? Iure id nam fuga asperiores repellat accusantium exercitationem nemo?</div>
           
        </div>
        <div className="flexcard flexcardOrange">
            <div className="flexcardNumber flexcardNumberOrange">04</div>
            <div className="flexs flex flexcardTitle">Maintenance</div>
            <div className="flexs flex flexcardText text-[18px]  leading-loose">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae,
                temporibus consectetur? Iure id nam fuga asperiores repellat accusantium exercitationem nemo?</div>
           
        </div>
</div>
  {/* <footer> &copy;Ceren Kurucu | 2020</footer> */}
   {/* ============================features===================== */}
   <div className="h-auto w-[full]  max-md:px-5 text-justify mb-8 px-12 max-sm:px-4 mt-10">
                <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
               Honey Iconics Provides Creative Website{" "} 
                  <span className="text-[32px] text-orange-500 font-serif">
                  Design features that create{" "}
                  </span>{" "}
                  a huge Customer engagement{" "}
                </h2>
                <p className="w-[90%] font-RobatoCondesed max-sm:w-full text-center px-10 max-sm:text-justify max-md:px-0 justify-center   ">
                  Through strategic experience and perfect execution, Honey
                  Iconics shapes web development success, ensuring that your
                  online presence crosses expectations and provides amazing
                  results.
                </p>
              </div>
   <div className="feature-page-section px-16">
          <div className="feature-container">
            <div className="feature-box">
              <div className="feature-img">
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
               </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                <div className="feature-imgone">
                  <h2>Start off(External meeting)</h2>
                </div>
                
              </div>
            </div>
          </div>
        </div>
    {/* ==========================features end============================ */}
         {/* ========================service explanation========================= */}
         <section className="px-16 py-6 max-md:px-4 dark:bg-gray-800">
        
         <div className="h-auto w-[full]  max-md:px-5 text-justify mb-8 px-12 max-sm:px-4 mt-10">
                <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
                Website design {" "} 
                  <span className="text-[32px] text-orange-500 font-serif">
                  services we offer{" "}
                  </span>{" "}
                </h2>
                <p className="w-[90%] font-RobatoCondesed max-sm:w-full text-center px-10 max-sm:text-justify max-md:px-0 justify-center   ">
                  Through strategic experience and perfect execution, Honey
                  Iconics shapes web development success, ensuring that your
                  online presence crosses expectations and provides amazing
                  results.
                </p>
              </div>
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-center  text-[35px] font-Opensanfour">
              Custom Web Design
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ============== */}
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
               Redesign Website
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Creating a powerful brand involves more than developing a
                      logo. The key is to develop a single brand that expresses
                      your values and identity. To ensure the accuracy of all
                      interactions, our team of experts will assist you in
                      creating a full brand identity that includes your logo,
                      color scheme, design, pictures, and messaging.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ========================= */}
             {/* ============== */}
             <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
              Wordpress Design
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Creating a powerful brand involves more than developing a
                      logo. The key is to develop a single brand that expresses
                      your values and identity. To ensure the accuracy of all
                      interactions, our team of experts will assist you in
                      creating a full brand identity that includes your logo,
                      color scheme, design, pictures, and messaging.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ========================= */}
             {/* ============== */}
             <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
                Landing Page Design 
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Creating a powerful brand involves more than developing a
                      logo. The key is to develop a single brand that expresses
                      your values and identity. To ensure the accuracy of all
                      interactions, our team of experts will assist you in
                      creating a full brand identity that includes your logo,
                      color scheme, design, pictures, and messaging.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ========================= */}
             {/* ============== */}
             <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
               Web UI/UX 
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Creating a powerful brand involves more than developing a
                      logo. The key is to develop a single brand that expresses
                      your values and identity. To ensure the accuracy of all
                      interactions, our team of experts will assist you in
                      creating a full brand identity that includes your logo,
                      color scheme, design, pictures, and messaging.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ========================= */}
            <div className="card1 ">
              <h3 className="font-semibold card-heading text-[35px] text-center  font-Opensanfour">
                CMS Web design
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex ">
                    <IoIosArrowForward className="mt-[6px] mr-2 small  " />
                    <p className="small ">
                      {" "}
                      Increase the visibility of your brand with strategic
                      planning and customized branding. Our creative and
                      strategic logo design services combine to create
                      eye-catching identities that connect with the people you
                      want to reach. Put trust in our experience to execute your
                      brand's vision with style and accuracy, differentiating
                      you from other businesses.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================service explanation end=================== */}
      {/* ============================Keywords===================== */}
      <div className="h-auto w-[full]  max-md:px-5 text-justify mb-8 px-16 max-sm:px-4 mt-10">
          <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
          List of{" "}
            <span className="text-[32px] text-orange-500 font-serif">
              Key words{" "}
            </span>{" "}
            {" "}
          </h2>
         
        </div>

        <div className="feature-page-sections px-16">
          <div className="feature-containers">
            <div className="feature-box">
              <div className="feature-img">
                <div className="key ">
                  <a href="#">website design company in coimbatore</a>
                </div>
                <div className="key ms-7 mt-">
                  <a href="#">best web design company in coimbatore</a>
                </div>
                <div className="key ms-7">
                  <a href="#">web design company near me</a>
                </div>
                <div className="key ms-7">
                  <a href="#">top web design companies</a>
                </div>
                <div className="key ms-7">
                  <a href="#">
                  web design company in tamilnadu
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  web development services near me
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  web design and development
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  responsive web design company
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  top website builders for small business
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  erp software development company
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  Software development companies in India
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  web design company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  web development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  website designing company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  leading web design company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  best software development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  crm software application development services in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  ecommerce website development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  software development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  software developer in coimbatore
                  </a>
                </div>

                <div className="key ms-7 mt-7">
                  <a href="#">ecommerce web design company</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">professional web design company</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">software development company in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">software development in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                    best software development services company in india
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                    software development services company in tamilnadu
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                    best software development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">digital marketing company in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">digital marketing in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">seo company coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">digital marketing services</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">digital marketing agency in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">best digital marketing company in coimbatore</a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  digital marketing company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  social media marketing company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  digital marketing services in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  search engine optimization companies
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  best digital marketing agency in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  best search engine optimization agency
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  digital marketing companies in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  best digital marketing agency in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">graphic designing company in coimbatore</a>
                </div>{" "}
                <div className="key ms-7 mt-7">
                  <a href="#">designing company in coimbatore</a>
                </div>{" "}
                <div className="key ms-7 mt-7">
                  <a href="#">mobile application development companies</a>
                </div>{" "}
                <div className="key ms-7 mt-7">
                  <a href="#">mobile app development companies in coimbatore</a>
                </div>{" "}
                <div className="key ms-7 mt-7">
                  <a href="#">app developer near me</a>
                </div>{" "}
                <div className="key ms-7 mt-7">
                  <a href="#">
                    mobile application development company in coimbatore
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  mobile software development company
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  mobile app developer
                  </a>
                </div>
                <div className="key ms-7 mt-7">
                  <a href="#">
                  mobile application development companies
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ==========================keywords end============================ */}

     
        {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What does Honey Iconics specialize in ?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Honey Iconics is a leading website development company based
                    in Coimbatore. We specialize in a range of digital services,
                    including website development, SEO (search engine
                    optimization), logo design, etc.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can you tell us more about your website development
                    services?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Certainly! We take pride in crafting custom websites
                    tailored to the unique needs of our clients. Our team of
                    experienced developers ensures that your website is not only
                    visually appealing but also highly functional and
                    user-friendly.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What is SEO, and how does Honey Iconics help businesses with
                    it?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    SEO, or Search Engine Optimization, is the process of
                    optimizing your website to rank higher in search engine
                    results. At Honey Iconics, we provide SEO services that
                    improve your website's visibility, driving organic traffic
                    and helping you reach your target audience effectively.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Do you offer logo design services as well?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, we do! Our skilled designers create memorable and
                    impactful logos that represent your brand's identity. A
                    well-designed logo can leave a lasting impression on your
                    audience, and we're here to ensure it's a positive one.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* =======================faq end========================== */}

      </section>
      <Footer />
      <MobileSocialIcons/>

    </>
  );
};

export default WebsiteTrichy;
