import "./App.css";

import "./pages/all-seo/seo.css";

import "./index.css";
import "./css-files/home.css";
import "./css-files/software-development.css";
import "./css-files/web-development.css";

import { Route, Routes } from "react-router-dom";

import ScroolToTop from "./components/Scrollings/auto-scroll-on-nav";
// import NavrBar from "./components/home/navBar/navbar";

import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import OurWork from "./pages/our-workflow";
import Blog from "./pages/blog";
import Contact from "./pages/contact";

import LocalSeo from "./pages/all-seo/local-seo";
import OnPageSeo from "./pages/all-seo/onpage-seo";
import OffPageSeo from "./pages/all-seo/offpage-seo";
import TechnicalPageseo from "./pages/all-seo/technical-seo";
import SoftwareDevelopment from "./pages/custom-software-development-company-coimbatore";
import WebDevelopment from "./pages/website-development/website-designing-company-coimbatore";
import AppDevelopment from "./pages/mobile-app-developers-coimbatore";
import PriceandPackage from "./pages/priceandpackage";
import Logopage from "./pages/branding/logo";
import Graphicpage from "./pages/branding/graphic-design";
import Staticwebsite from "./pages/website-development/static-website";
import Dynamicwebsite from "./pages/website-development/dynamic-website";
import Ecommercewebsite from "./pages/website-development/e-commerce";
import Googlepage from "./pages/google-adwords";
import Hiredevelopers from "./pages/hire-developers-page";
import Custompage from "./pages/custom404page";
import Digitalmarketing from "./pages/digital-marketing-in-coimbatore";
import Privacypage from "./pages/privacy-policy";
import Website from "./pages/hidden-files/website-madurai";
import Blogpage from "./pages/blog-page";
import WebsiteMadurai from "./pages/hidden-files/website-madurai";
import WebsiteChennai from "./pages/hidden-files/website-chennai";
import WebsiteSalem from "./pages/hidden-files/website-salem";
import WebsiteTrichy from "./pages/hidden-files/website-trichy";
import WebsiteCoimbatore from "./pages/hidden-files/website-coimbatore";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ScroolToTop />
              <Home />
            </>
          }
        />
        <Route
          path="/about-us-software-development-company-in-Coimbatore"
          element={
            <>
              <ScroolToTop />
              <AboutUs />{" "}
            </>
          }
        />
        <Route
          path="/digital-marketing-company-coimbatore"
          element={
            <>
              <ScroolToTop />
              <Digitalmarketing />
            </>
          }
        />
        <Route
          path="/seo-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <LocalSeo />
            </>
          }
        />
        <Route
          path="/on-page-seo-services"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OnPageSeo />
            </>
          }
        />
        <Route
          path="/best-seo-services-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OffPageSeo />
            </>
          }
        />
        <Route
          path="/best-technical-seo"
          element={
            <>
              {" "}
              <ScroolToTop />
              <TechnicalPageseo />
            </>
          }
        />
        <Route
          path="/software-development-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <SoftwareDevelopment />
            </>
          }
        />
        <Route
          path="/website-development-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebDevelopment />
            </>
          }
        />
        <Route
          path="/mobile-app-developers-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <AppDevelopment />
            </>
          }
        />
        <Route
          path="/static-website-development-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Staticwebsite />
            </>
          }
        />
        <Route
          path="/dynamic-website-designing-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Dynamicwebsite />
            </>
          }
        />

        <Route
          path="/ecommerce-web-design-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Ecommercewebsite />
            </>
          }
        />
        <Route
          path="/Price-package"
          element={
            <>
              {" "}
              <ScroolToTop />
              <PriceandPackage />
            </>
          }
        />
        <Route
          path="/portfolio"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OurWork />
            </>
          }
        />
        <Route
          path="/blog"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Blog />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Contact />
            </>
          }
        />
        <Route
          path="/hire-developers"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Hiredevelopers />
            </>
          }
        />
        <Route
          path="/logo-design-branding-services"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Logopage />
            </>
          }
        />
        <Route
          path="/website-graphic-design-services"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Graphicpage />
            </>
          }
        />
        <Route
          path="/google-advertising-company-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Googlepage />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Privacypage />
            </>
          }
        />
         <Route
          path="/blog-page"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Blogpage />
            </>
          }
        />
       
        <Route
          path="/website-design-company-in-madurai"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebsiteMadurai />
            </>
          }
        />
        <Route
          path="/website-design-company-in-chennai"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebsiteChennai/>
            </>
          }
        />
        <Route
          path="/website-design-company-in-coimbatore"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebsiteCoimbatore />
            </>
          }
        />
        <Route
          path="/website-design-company-in-salem"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebsiteSalem />
            </>
          }
        />
        <Route
          path="/website-design-company-in-trichy"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebsiteTrichy />
            </>
          }
        />

        <Route path="*" element={<Custompage />} />
      </Routes>
    </>
  );
}

export default App;
