import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Responsive from "../images/Home/Image-Sec-Two.jpg";
import { IoIosArrowForward } from "react-icons/io";
import html from "../images/hire-developers/html.png";
import css from "../images/hire-developers/css.png";
import js from "../images/hire-developers/js.png";
import react from "../images/hire-developers/react.png";
import angular from "../images/hire-developers/angular.png";
import php from "../images/hire-developers/php.png";
import flutter from "../images/hire-developers/flutter.webp";
import digital from "../images/hire-developers/digital.png";

import { Link } from "react-router-dom";
import "../css-files/hire-developers.css";
import Footer from "../components/footer/footer";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "./mobile-social-links";

export default function Hiredevelopers() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const whatsappNumber = "+916379453263";

  useEffect(() => {
    if (
      submitted &&
      name !== "" &&
      phone !== "" &&
      message !== "" &&
      selectedOption !== ""
    ) {
      const whatsappLink = `https://wa.me/${whatsappNumber}?text=Name:%20${name}%0APhone:%20${phone}%0AMessage:%20${message}%0ASelected%20Option:%20${selectedOption}`;
      window.open(whatsappLink, "_blank");
      setSubmitted(false);
    }
  }, [submitted, name, phone, message, selectedOption, whatsappNumber]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <>
   <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hire developers in Coimbatore</title>
          <meta
            name="description"
            content="Enhance the visual aspect of your brand by working with a graphic design company in Coimbatore. We create graphically attractive designs that boost your brand's visibility and engage your audience with attention to detail and creativity"
          />
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/website-graphic-design-services"
          />
        </Helmet>
      </div>
      {/* =====================logo================= */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Welcome to Honeyiconics!</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                At Honeyiconics, we believe in the power of talent, creativity, and collaboration. We bring innovative ideas to our team. If you are looking for a place where your skills are valued, your ideas are heard, and your growth is nurtured, then you've come to the right place.constantly on the lookout for passionate individuals who can bring fresh perspectives.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================logo end================== */}

      {/* =========================definition====================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
            Join Our Team of 
              <span className="font-serif mr-3 ml-3 text-orange-500">
              Talented Developers at Honeyiconics!
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
            Are you a passionate developer looking for an exciting opportunity to work on cutting-edge projects and collaborate with a team of like-minded individuals? Look no further than Honeyiconics! We are always on the lookout for top-notch developers who are eager to innovate, learn, and grow with us.
              <br />
              <br />
              We are committed to your professional development and growth. Whether it's through hands-on projects, training programs, or access to the latest tools and technologies, we provide the resources you need to thrive in your career.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>

      {/* =========================definition end=========================== */}

      {/* ------------------------------------ Section - One ---------------------- */}
      <section>
        <div>
          <div className="px-10 bg-white max-sm:px-4 mb-9">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto ">
                <h2 className="text-[35px] uppercase font-RobatoCondesed max-sm:text-[35px] max-md:text-center  text-slate-900">
                  Hire Front-End Developer
                </h2>
                <h2 className="text-[25px] font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Leading Web Development Company in Coimbatore
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  At Honey Iconics in Coimbatore, we are your dedicated partner
                  for all-encompassing digital solutions. Our comprehensive
                  services include mobile app development, website design and
                  development, digital marketing, and software development. With
                  a keen focus on delivering innovative and customized
                  solutions, we cater to your unique business needs.
                  <br /> <br />
                  As the best website design company in Coimbatore, we're
                  dedicated to helping you achieve all of your digital goals.
                  Our talented team of designers and developers, located in the
                  center of Coimbatore, specializes in building websites that
                  always surpass your expectations. We prioritize working
                  together to make sure that each project is driven by your
                  unique ideas. We offer advanced and creative solutions by
                  staying ahead of the most recent developments in the business.
                  Our work is based on reliability and quality, and your success
                  online is our main goal.
                </p>
                <Link to={"/contact"}>
                  {" "}
                  <button className="btn-hover color-2 mt-10 pt-2 pb-2 pl-8 pr-8">
                    Get in Touch
                  </button>
                </Link>
              </div>{" "}
              <div className="w-full h-auto px-5 pt-5">
                <form onSubmit={handleFormSubmit} className="">
                  <div className="">
                    <h2 className="text-center font-RobatoCondesed font-normal text-[42px] uppercase">
                      Contact Form
                    </h2>

                    <div className="pl-10 max-lg:pl-0">
                      <div>
                        <h2 className="mb-2 font-RobatoCondesed text-[18px] tracking-wider">
                          Name
                        </h2>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="border-[1px] pl-2 py-2 border-black w-full form-control"
                        />
                      </div>

                      <div>
                        <h2 className="mb-2 mt-5 font-RobatoCondesed text-[18px] tracking-wider">
                          Number
                        </h2>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          className="border-[1px] pl-2 py-2 border-black w-full form-control "
                        />
                      </div>
                      <div>
                        <h2 className="mb-2 mt-5 font-RobatoCondesed text-[18px] tracking-wider">
                          Select
                        </h2>
                        <select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          className="border-[1px] pl-2 py-2 border-black w-full form-control"
                        >
                          <option value="">Select an option</option>
                          <option value="Front-End Developer">
                            Front-End Developer
                          </option>
                          <option value="Back-End Developer">
                            Back-End Developer
                          </option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Mobile App Development">
                            Mobile App Development
                          </option>
                          <option value="Graphic Design">Graphic Design</option>
                        </select>
                      </div>

                      <div>
                        <h2 className="mb-2 mt-5 font-RobatoCondesed text-[18px] tracking-wider">
                          Message
                        </h2>
                        <textarea
                          name="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          cols="30"
                          rows="5"
                          className="pt-3 pl-3 w-full border-[1px] border-gray-600 form-control"
                        ></textarea>
                      </div>
                      <button className="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5 w-[35%] text-3xl mt-10 max-sm:w-[50%]">
                        <span className="text-[18px] font-medium">Submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------------------ Section - One end ---------------------- */}

      {/* ====================================================== */}
      <section className="px-16 py-6 max-md:px-4 dark:bg-gray-800">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
            <div className="card1 text-center">
              <img
                src={html}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7"
              />
              <h3 className="font-semibold card-heading text-center text-[35px] font-Opensanfour">
                HTML
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small">
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
            {/* ========================================= */}
            <div className="card1 text-center">
              <img
                src={css}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7"
              />
              <h3 className="font-semibold card-heading text-center text-[35px] font-Opensanfour">
                CSS
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small">
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
            {/* ========================================= */}
            <div className="card1 text-center">
              <img
                src={js}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[20%] h-[20%] mb-7"
              />
              <h3 className="font-semibold card-heading text-center text-[35px] font-Opensanfour">
                JAVA SCRIPT
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small">
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ======================================= */}
            {/* ========================================= */}
            <div className="card1 text-center">
              <img
                src={react}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[19%] h-[15%] mb-7"
              />
              <h3 className="font-semibold card-heading text-center text-[35px] font-Opensanfour">
                REACT JS
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small">
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
            {/* ====================front end=================== */}
            <div className="card1 text-center">
              <img
                src={angular}
                alt="html-logo"
                title="html-logo"
                className="mx-auto w-[35%] h-[20%] mb-7"
              />
              <h3 className="font-semibold card-heading text-center text-[35px] font-Opensanfour">
                ANGULAR
              </h3>
              <div>
                <ul className="mt-4 font-serif text-[18px]">
                  <li className="flex">
                    <IoIosArrowForward className="mt-[6px] mr-2 small" />
                    <p className="small">
                      The basis of your brand registration is your logo. It's
                      the first thing that prospective consumers discover about
                      you, therefore, it should be timeless, memorable, and a
                      true representation of the personality and values of your
                      business. At Honey Iconics, we collaborate closely with
                      you to completely understand your goals and provide a
                      unique logo for your business.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================frontend end============================= */}
      {/* ================================backend=====================       */}
      <section>
        <div className="relative mt-5 mb-20">
          <div className="pt-10 pb-32 sec-four ">
            <div className="h-auto w-[full]  max-md:px-5   text-justify   mb-5 px-12 max-sm:px-4">
              <h2 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
                Hire{" "}
                <span className="text-[32px] text-orange-500 font-serif">
                  Php laravel Back-End{" "}
                </span>{" "}
                Developer{" "}
              </h2>
              <p className="w-[90%] font-RobatoCondesed max-sm:w-full mt-5  text-center px-10  max-sm:text-justify max-md:px-0 justify-center   ">
                The basis of your brand registration is your logo. It's the
                first thing that prospective consumers discover about you,
                therefore, it should be timeless, memorable, and a true
                representation of the personality and values of your business.
                At Honey Iconics, we collaborate closely with you to completely
                understand your goals and provide a unique logo for your
                business.The basis of your brand registration is your logo. It's
                the first thing that prospective consumers discover about you,
                therefore, it should be timeless, memorable, and a true
                representation of the personality and values of your business.
                At Honey Iconics, we collaborate closely with you to completely
                understand your goals and provide a unique logo for your
                business.
              </p>
            </div>

            <section>
              <div className="mx-10 mt-15 max-md:mx-6">
                <h2 className="mt-10 text-[35px] uppercase font-RobatoCondesed  font-normal text-center max-sm:text-[32px] max-sm:text-center">
                  Hire Pre-Vetted Php laravel Developer for <br />
                  <span className="ml-3 mr-3 font-serif text-orange-500">
                    Back-end Web Development
                  </span>
                </h2>
              </div>

              <div className="px-10 max-md:px-5">
                <h2 className="mt-8 text-[20px] font-serif text-center italic"></h2>
                <section className="py-6 dark:bg-gray-800">
                  <div className="container flex flex-col justify-center p-4 mx-auto">
                    <div className="grid font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          Application Development
                        </span>
                      </div>
                      {/* ===== */}
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          E-Commerce Development
                        </span>
                      </div>
                      {/* ===== */}
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          CMS<br/> Development
                        </span>
                      </div>
                      {/* ===== */}
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          Integration Services
                        </span>
                      </div>
                      {/* ====== */}
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          Plugin Development
                        </span>
                      </div>

                      {/* ===== */}
                      <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                        <img
                          className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                          src={php}
                          alt="loading"
                          title="php"
                        />
                        <span className=" ml-5 mt-3 text-[18px] text-black">
                          Migration<br/> Services
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* ====================backend end============================= */}

      {/* ============================graphic design====================== */}
      <section>
        <div className="px-16 font-RobatoCondesed mt-16 max-md:px-6 mb-10">
          <div>
            <h2 className="text-center uppercase text-[35px] max-sm:text-[30px] font-medium ">
              Hire Graphic design
            </h2>
            <p className="w-[90%] font-RobatoCondesed max-sm:w-full mt-5  text-center px-10  max-sm:text-justify max-md:px-0 justify-center   ">
              Customized Website Development Services In India Across Different
              Industrial Sectors. Customized Website Development Services In India Across Different
              Industrial Sectors. Customized Website Development Services In India Across Different
              Industrial Sectors. Customized Website Development Services In India Across Different
              Industrial Sectors
            </p>
            <section className="py-6 ">
              <div className="container flex flex-col justify-center p-4 mx-auto">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5   pt-5">Photoshop</p>
                  </div>

                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5   pt-5">Illustrator</p>
                  </div>

                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5  pt-5">Adobe XD</p>
                  </div>

                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5   pt-5">Canva</p>
                  </div>

                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5  pt-5">Figma</p>
                  </div>

                 

                  <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                    <h2 className="text-[50px] text-orange-500">
                      <i class="fa-brands fa-slack"></i>
                    </h2>
                    <p className="text-center pb-5  text-[25px] px-5  pt-5">Coreldraw</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* ========================graphic design end======================== */}

      {/* ============================mobile app========================== */}

      <section>
        <div className="mx-10 max-md:mx-6 mb-10">
          <h2 className="text-[35px] uppercase font-RobatoCondesed  font-normal text-center max-sm:text-[32px] max-sm:text-center">
            Hire Pre-Vetted Flutter Developer for <br />
            <span className="ml-3 mr-3 font-serif text-orange-500">
              Mobile App Development
            </span>
          </h2>
        </div>

        <div className="px-10 max-md:px-5">
          <p className="w-[90%] font-RobatoCondesed max-sm:w-full mt-5  text-center px-10  max-sm:text-justify max-md:px-0 justify-center   ">
            The basis of your brand registration is your logo. It's the first
            thing that prospective consumers discover about you, therefore, it
            should be timeless, memorable, and a true representation of the
            personality and values of your business. At Honey Iconics, we
            collaborate closely with you to completely understand your goals and
            provide a unique logo for your business.The basis of your brand
            registration is your logo. It's the first thing that prospective
            consumers discover about you, therefore, it should be timeless,
            memorable, and a true representation of the personality and values
            of your business. At Honey Iconics, we collaborate closely with you
            to completely understand your goals and provide a unique logo for
            your business.
          </p>
          <h2 className="mt-8 text-[20px] font-serif text-center italic"></h2>
          <section className="py-6 dark:bg-gray-800">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Personalized Consultation
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Platform <br /> Migration
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Cross Platform Applications
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Support and Maintanance
                  </span>
                </div>
                {/* ====== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Flutter Enterprise Apps
                  </span>
                </div>

                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={flutter}
                    alt="loading"
                    title="flutter"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Intuitive and Flexible UI
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* ========================mobile app end=========================== */}

      {/* ========================digital marketing==================== */}

      <section className="">
        <div>
          <div className="px-10 bg-white max-sm:px-4 mt-10">
            <div className="flex max-md:flex-col">
              <div className="w-full h-auto ">
                <h2 className="text-[35px] uppercase font-RobatoCondesed max-sm:text-[35px] max-md:text-center  text-slate-900">
                  Hire Digital marketing
                </h2>
                <h2 className="text-[25px] font-serif text-orange-500 uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                  Leading Web Development Company in Coimbatore
                </h2>

                <p className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                  At Honey Iconics in Coimbatore, we are your dedicated partner
                  for all-encompassing digital solutions. Our comprehensive
                  services include mobile app development, website design and
                  development, digital marketing, and software development. With
                  a keen focus on delivering innovative and customized
                  solutions, we cater to your unique business needs.
                  <br /> <br />
                  As the best website design company in Coimbatore, we're
                  dedicated to helping you achieve all of your digital goals.
                  Our talented team of designers and developers, located in the
                  center of Coimbatore, specializes in building websites that
                  always surpass your expectations. We prioritize working
                  together to make sure that each project is driven by your
                  unique ideas. We offer advanced and creative solutions by
                  staying ahead of the most recent developments in the business.
                  Our work is based on reliability and quality, and your success
                  online is our main goal.
                </p>
                <Link to={"/contact"}>
                  {" "}
                  <button className="btn-hover color-2 mt-10 pt-2 pb-2 pl-8 pr-8">
                    Get in Touch
                  </button>
                </Link>
              </div>{" "}
              <div className="w-full h-auto px-5 pt-5">
                <img
                  className="object-cover w-full h-full"
                  src={Responsive}
                  alt="loading"
                  title="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="px-10 max-md:px-5 mb-10">
          <section className="py-6 dark:bg-gray-800">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
           {/* ===== */}
           <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    On page SEO
                  </span>
                </div>
                {/* ====== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Off page SEO
                  </span>
                </div>

                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                Local SEO
                  </span>
                </div>
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    Technical SEO
                  </span>
                </div>
                {/* ====== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    SMO(social media optimization)
                  </span>
                </div>

                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-lg shadow-lg shadow-gray-500">
                  <img
                    className="h-6 mt-2 ml-5 w-[15%] h-[80%]"
                    src={digital}
                    alt="loading"
                    title="digital"
                  />
                  <span className=" ml-5 mt-5 text-[18px] text-black">
                    SMM(Social media marketing)
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* =======================digital marketing end======================= */}
      <Footer />
      <MobileSocialIcons/>
    </>
  );
}
