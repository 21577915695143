import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StaticImage from "../../images/Web-development/Static-images-removebg.png";
import Ecommerceone from "../../images/e-commerce page/Ecomorce.png";
import Ecommercetwo from "../../images/e-commerce page/Catring.png";
import Ecommercethree from "../../images/e-commerce page/WhatsApp Image 2023-12-21 at 04.50.19_43acbdc6.jpg";
import Ecommercefour from "../../images/e-commerce page/enterprises.png";
import "../../css-files/development.css";
import Footer from "../../components/footer/footer";
import Headerone from "../../components/NavBars/header-one/headerone";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "../mobile-social-links";

export default function Ecommercewebsite() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Best Web Design Company in Coimbatore | Web Design Company in
            Coimbatore{" "}
          </title>
          <meta
            name="description"
            content="Discover our e-commerce website services designed to boost your brand's online presence in Coimbatore. From seamless shopping experiences to secure transactions, we create websites that drive conversions and foster growth."
          />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
          />
        </Helmet>
      </div>
      {/* =====================static Website================== */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Dynamic Website</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                Explore a globe of dynamic websites designed to grow with your company. Our team is skilled at creating engaging online platforms that satisfy your requirements and attract visitors.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================static Website end================== */}

      {/* ==========================what is static website=========================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              Dynamic
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Website
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
            Dynamic websites are characterized by their ability to display personalized content and interactive features, such as user accounts and real-time updates. They rely on server-side processing and databases to generate web pages dynamically in response to user requests. 

              <br />
              <br />
              These websites are ideal for businesses that require frequent content updates or user interactions, such as e-commerce platforms, social networks, and online forums. With dynamic websites, users can experience a more engaging and tailored browsing experience, leading to higher user satisfaction and conversion rates. Overall, dynamic websites offer flexibility, scalability, and enhanced functionality compared to static websites.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* ==========================what is static website end=========================== */}

      {/* =======================why choose========================= */}
      <section>
        <div className="px-16 font-RobatoCondesed py-16 mt-10 bg-gray-100 max-md:px-6">
          <div>
            <h2 className="text-[35px] font-medium text-center uppercase">
              Our
              <span className="ml-3 mr-3 font-serif text-orange-500">
                Dynamic Website Development
              </span>
              <br />
              Services
            </h2>
          </div>

          <div className="flex mt-10 font-RobatoCondesed max-md:flex-col container ">
            <div className="w-[50%] max-md:w-full">
              <center>
                <img
                  className="object-cover w-full h-96 "
                  src={StaticImage}
                  alt="loading"
                  title="Static-Image"
                />
              </center>
            </div>
            <div className="w-[50%] max-md:w-full">
              <h2 className="font-medium uppercase text-[32px]">
                Why choose Dynamic Website ?
              </h2>
              <div className="h-1 mt-3 bg-orange-500 w-75 rounded-xl"></div>
              <p className="mt-5 leading-loose text-justify">
              Dynamic websites, characterized by their interactive and flexible nature, offer a myriad of advantages that propel businesses into the forefront of the digital landscape. One key advantage lies in their ability to engage users with real-time content updates, creating a dynamic and personalized experience. This interactivity fosters longer user interactions, increasing the chances of conversion and establishing a positive online brand perception.
              </p>
              <p className="mt-5 leading-loose">
                Hosting flexibility and search engine friendliness are integral
                aspects of our approach, ensuring that your website not only
                stands out but also performs exceptionally well.
              </p>
              <div>
                <h2>CSS TODO List Design Inspiration</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==========================why choose end======================== */}

      {/* =================portfolio======================= */}

      <div className="portfolio-example-section mt-5">
        <div>
          <h2 className="text-[35px] font-RobatoCondesed font-semibold text-center text-orange-500">
            Our Portfolio{" "}
          </h2>
          <p className="text-xl font-thin text-center ">
            Our Latest Work For Your Business{" "}
          </p>
        </div>
        <div className="portfolio-example-container container">
          <div className="portfolio-image-boxes">
            <div className="portfolio-imgone">
              <img
                src={Ecommerceone}
                alt="Portfolio"
                title="Ecommerce-website"
              />
            </div>
            <div className="portfolio-imgone">
              <img
                src={Ecommercetwo}
                alt="Portfolio"
                title="Ecommerce-website"
              />
            </div>
          </div>
          <div className="portfolio-image-box">
            <div className="portfolio-imgtwo">
              <img
                src={Ecommercethree}
                alt="Portfolio"
                title="Ecommerce-website"
              />
            </div>
            <div className="portfolio-imgtwo">
              <img
                src={Ecommercefour}
                alt="Portfolio"
                title="Ecommerce-website"
              />
            </div>
          </div>
        </div>
      </div>

      {/* =================portfolio end======================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What are dynamic websites, and why are they beneficial for
                    businesses in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Dynamic websites are websites that display content
                    dynamically, meaning they can change based on user
                    interaction, data input, or other factors. They offer
                    businesses in Coimbatore several benefits such as
                    personalized user experiences, real-time content updates,
                    interactive features, and scalability.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics approach the development of dynamic
                    websites for businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics takes a comprehensive approach to developing
                    dynamic websites for businesses in Coimbatore. We start by
                    understanding the client's objectives, target audience, and
                    desired features. Our team then designs and develops dynamic
                    websites with user-friendly interfaces, interactive
                    elements, content management systems, and custom
                    functionalities.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can Honeyiconics create custom functionalities and features
                    for dynamic websites in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, Honeyiconics specializes in creating custom
                    functionalities and features for dynamic websites in
                    Coimbatore. Whether it's a user login system, interactive
                    forms, e-commerce functionality, real-time data updates, or
                    dynamic content generation, we can tailor the website to
                    meet the specific needs of our clients.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that dynamic websites developed
                    for businesses in Coimbatore are visually appealing and
                    responsive across devices?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    We prioritize visual appeal and responsiveness in dynamic
                    website development for businesses in Coimbatore. Our team
                    uses responsive design principles, conducts cross-device
                    testing, optimizes viewport settings, and ensures that the
                    website looks and functions seamlessly on desktops, tablets,
                    and smartphones.
                  </p>
                </details>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}
      <Footer />
      <MobileSocialIcons />
    </>
  );
}
