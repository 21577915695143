import React from "react";
import SectionOnImage from "../images/app-development/Section-One-Imagge.png";
// import ImageBannerTwo from "../images/app-development/5224664.png";
import ImageOneService from "../images/app-development/Service-image-one.svg";
import ImageTwoService from "../images/app-development/Service-image-two.svg";
import ImageThreeService from "../images/app-development/Service-image-three.svg";
import ImageFourServices from "../images/app-development/Service-image-four.svg";
import ImageFiveServices from "../images/app-development/Service-image-five.svg";
import ImageSixServices from "../images/app-development/Service-image-six.svg";
import ImageSevenServices from "../images/app-development/Service-image-seven.svg";
import ImageEightServices from "../images/app-development/Service-image-eight.svg";

import TypesOne from "../images/app-development/Types-apdOne.svg";
import TypesTwo from "../images/app-development/Types-apdTwo.svg";
import TypesThree from "../images/app-development/Types-apdThree.svg";
import TypesFour from "../images/app-development/Types-apdFour.svg";
import TypesFive from "../images/app-development/Types-apdFive.svg";
import TypesSix from "../images/app-development/Types-apdSix.svg";

import NotesIcon from "../images/app-development/NOtes-Icon.png";
import SmartPhone from "../images/app-development/SmartPhone.png";
import Medal from "../images/app-development/medal-one.png";

import Phone from "../images/app-development/ok.webp";
import TecImageOne from "../images/app-development/Flutter.png";
import TecImageTwo from "../images/app-development/dart.png";
import TecImageThree from "../images/app-development/Kotlin.png";
import TecImageFour from "../images/app-development/ReactNative.png";
import TecImageFive from "../images/app-development/Firebase.png";
import TecImageSix from "../images/app-development/Php.png";
// import Tecnology from "../images/app-development/Technology.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../components/footer/footer";
import Headerone from "../components/NavBars/header-one/headerone";
import NavOne from "../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "./mobile-social-links";
const AppDevelopment = () => {
  const handleImageDownload = (imageSrc, imageName) => {
    const link = document.createElement("a");
    link.href = imageSrc;
    link.download = imageName;
    document.body.appendChild(link);
    link.onContextMenu();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Honey Iconics - Mobile App Developers in Coimbatore </title>
          <link
            rel="canonical"
            href="
            https://honey-iconicss.com/App-development
"
            content="Are you ready to elevate your business with cutting-edge mobile solutions? Look no further!  Honey Iconics stands proud as the best mobile application company in Coimbatore, delivering innovative and bespoke mobile applications that redefine user experiences.
"
          />

          <meta
            name="description"
            content="Honey Iconics is a trusted mobile app development company in Coimbatore. Our expert app developers deliver customized solutions for businesses. Contact us today."
          />
        </Helmet>
      </div>
      <section>
        <div>
          <section className="wrapper mb-20 pb-20">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full md:px-16 max-lg:pl-none max-md:px-16 max-sm:px-6 ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[2.8rem]"
                >
                  <span>ANDROID APP DEVELOPMENT COMPANY</span>
                </h1>
                <p className="mt-6 md:px-18 font-RobatoCondesed text-[18px] max-sm:text-[15px] tracking-wider text-center text-white ">
                  Are you looking for the best Android app development company?
                  We design and develop Android apps for startups and
                  businesses. Contact us to validate your order idea and get a
                  free consultation.
                </p>

                <center>
                    <Link to={"/contact"}>
                      {" "}
                      <button className="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                        <span className=" text-[18px] font-medium">
                          Contact Us{" "}
                        </span>
                      </button>{" "}
                    </Link>
                  </center>
              </div>
            </div>
          </section>
        </div>

        <section>
          <div className="w-auto px-16 max-sm:px-6 font-RobatoCondesed">
            <h2 className=" uppercase text-[38px] font-Nuito max-sm:text-[25px]">
              Mobile{" "}
              <span className="font-serif text-orange-500 ">
                App Development Company
              </span>{" "}
              in Coimbatore
            </h2>
          </div>
          <div className="flex px-16 font-RobatoCondesed max-sm:px-6 max-lg:flex-col">
            <div className="w-[60%] max-lg:w-full">
              <p className="text-[18px] text-justify mt-8 leading-loose ">
                We are the top Coimbatore-based company offering services for
                developing mobile apps for iOS and Android. For enterprise
                businesses, we create mobile apps that are highly functional,
                visually appealing in the app store, and performance-focused. As
                a seasoned Coimbatore mobile app developer, we go beyond simple
                website development and create an experience for the user rather
                than just releasing the app. Our mobile app developers are
                highly skilled at creating goal-oriented, performance-driven,
                and business-focused apps for your upcoming venture. Only
                competent mobile app developers with a strong creative and
                analytical background and experience writing custom hand-coded
                applications are employed by us. We have created over thirty
                mobile applications over the years for popular operating systems
                such as Windows, iOS, Android, and cross-browser phone apps.
              </p>
            </div>

            <div className="w-[40%] max-lg:w-[100%]">
              <center>
                <img
                  className="object-contain h-96"
                  src={SectionOnImage}
                  title="mobile-image"
                  alt="loading"
                  onContextMenu={() =>
                    handleImageDownload(SectionOnImage, "SectionOnImage")
                  }
                />{" "}
              </center>
            </div>
          </div>
        </section>

        {/* ------- Why Mobile App is Important?  ----- */}

        <section>
          <div className="px-16 font-RobatoCondesed mt-8 max-sm:px-6">
            <h2 className="uppercase max-sm:text-[18px] py-2 font-medium max-sm:pl-3 pl-6 w-[48%]  text-[28px] tracking-wide   text-white  bg-orange-500 rounded-full max-lg:w-full">
              Why Mobile App is Important ?
            </h2>

            <p className="text-[18px] text-justify mt-8 leading-8">
              Mobile apps provide users with easy access to information,
              services, and products from anywhere at any time. This level of
              accessibility enhances user convenience and satisfaction. Apps
              allow for personalized user experiences. They can tailor content,
              recommendations, and features based on user preferences, behavior,
              and location, creating a more engaging and relevant experience.
              Mobile apps provide a platform for direct interaction with users.
              Through push notifications, in-app messaging, and other features,
              apps can engage users and keep them informed about updates,
              promotions, and relevant information.
            </p>

            <p className="text-[18px] text-justify mt-8 leading-8">
              We have different development approaches and we use industry
              standards in converting your web app into a native mobile app.
              Using Xamarin and Phonegap technologies, we support cross-platform
              apps, and apps for Windows, IOS, and Android phones.
            </p>
          </div>
        </section>
        {/* ------- Mobile App Development Services BIT Studios Offers  ----- */}
        <section>
          <div className="px-10 font-RobatoCondesed mt-10 max-sm:px-6">
            <div className="flex ">
              <div className="w-2 h-12 mt-1 mr-5 bg-orange-500 rounded-lg max-sm:mr-2"></div>
              <h2 className=" max-sm:text-[25px] uppercase text-[38px]">
                Mobile App Development Services
                <span className="font-serif ml-3 text-orange-500">
                  Honey Iconics Offers
                </span>
              </h2>
            </div>

            <p className="mt-5 max-sm:pl-0 italic text-[18px] font-medium font-serif pl-7">
              BIT Studios helps businesses of all sizes create and improve
              mobile apps, from idea to launch and beyond.
            </p>

            <section className="py-6">
              <div className="container flex flex-col justify-center p-4 mx-auto">
                <div className="grid grid-cols-1 gap-4 place-items-center lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2">
                  <div className="border-[2px] pt-10   hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300  rounded-3xl text-center  w-[100%]  h-60">
                    <center>
                      <img
                        className="pr-3"
                        src={ImageOneService}
                        title="service-image"
                        alt="loading"
                      />
                      <h2 className="mt-8 text-[20px] font-medium">
                        Custom App Development Services
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3   hover:transition-all-[10s] transition-all-[8s]   border-gray-300 rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        className="pr-3"
                        src={ImageTwoService}
                        title="service-image"
                        alt="loading"
                      />
                      <h2 className="mt-8 text-[20px] font-medium">
                        Custom Android App Development
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300 rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageThreeService}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium">
                        Custom iOS App Development Services
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300  rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageFourServices}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium ">
                        Machine Learning Solutions Cross-Platform App
                        Development
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300  rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageFiveServices}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium px-3">
                        Hybrid App Development
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300  rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageSixServices}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium px-3">
                        Progressive Web App Development Progressive
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10  hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300  rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageSevenServices}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium">
                        Enterprise Mobile App Development
                      </h2>
                    </center>
                  </div>

                  <div className="border-[2px] pt-10   hover:rotate-3  hover:transition-all-[10s] transition-all-[8s]  border-gray-300 rounded-3xl text-center  w-[100%] px-3 h-60">
                    <center>
                      <img
                        src={ImageEightServices}
                        alt="loading"
                        title="service-image"
                      />
                      <h2 className="mt-8 text-[20px] font-medium">
                        Mobile App Design
                      </h2>
                    </center>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* ========================= Types of Android Applications We Develop ========================= */}
        <div className="px-10 mt-10 max-sm:px-6 font-RobatoCondesed">
          <div className="flex ">
            <div className="w-2 h-12 mt-1 mr-5 bg-orange-500 rounded-lg"></div>
            <h2 className=" text-[38px] max-sm:text-[35px ] uppercase">
              Types of
              <span className="ml-2 mr-2 font-serif text-orange-500">
                Android Applications
                <br />
              </span>
              We Develop
            </h2>
          </div>

          <section className="py-6 ">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-1 gap-4 place-items-center lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
                <div className="border-[2px] shadow-md shadow-gray-400 pt-10 px-3 border-gray-300 rounded-3xl text-center  w-auto h-80 py-8 max-md:h-auto">
                  <center>
                    <img
                      className=""
                      src={TypesOne}
                      alt="loading"
                      title="service-image"
                    />
                    <h2 className="mt-5 text-[28px] font-bold">
                      Custom Android Apps
                    </h2>
                    <p className="mt-3 text-justify">
                      In Honey Iconics, we will tailor custom Android apps that
                      resonate with your specific business needs and
                      requirements.
                    </p>
                  </center>
                </div>

                <div className="border-[2px] shadow-md shadow-gray-400 pt-10 px-3 border-gray-300 rounded-3xl text-center  w-auto h-80 py-8">
                  <center>
                    <img
                      className=""
                      src={TypesTwo}
                      alt="loading"
                      title="service-image"
                    />
                    <h2 className="mt-5 text-[28px] font-bold">
                      E-Commerce Apps
                    </h2>
                    <p className="mt-3 text-justify">
                      We can build user-friendly e-commerce apps, streamlining
                      online shopping experiences for businesses of all sizes.
                    </p>
                  </center>
                </div>

                <div className="border-[2px] shadow-md shadow-gray-400 pt-10 px-3 border-gray-300 rounded-3xl text-center  w-auto h-80 py-8">
                  <center>
                    <img className="" src={TypesThree} alt="loading" />
                    <h2 className="mt-5 text-[28px] font-bold">Banking Apps</h2>
                    <p className="mt-3 text-justify">
                      Honey Iconics Studios is an Android app company that can
                      create secure, efficient banking apps that bring
                      financial.
                    </p>
                  </center>
                </div>

                <div className="border-[2px] shadow-lg shadow-gray-400 pt-10 px-3 border-gray-300 rounded-3xl text-center w-auto h-80 py-8">
                  <center>
                    <img className="" src={TypesFour} alt="loading" />
                    <h2 className="mt-5 text-[28px] font-bold">Payment Apps</h2>
                    <p className="mt-3 text-justify">
                      Our team will develop Android mobile apps for seamless
                      payments, facilitating quick and safe transactions for
                      businesses and consumers.
                    </p>
                  </center>
                </div>

                <div className="border-[2px] pt-10 shadow-lg shadow-gray-400 px-3 border-gray-300 rounded-3xl text-center  w-auto h-80 py-8">
                  <center>
                    <img className="" src={TypesFive} alt="loading" />
                    <h2 className="mt-5 text-[28px] font-bold">
                      Social Media Application
                    </h2>
                    <p className="mt-3 text-justify">
                      We can design engaging social media apps, fostering
                      communities and enhancing online interaction.
                    </p>
                  </center>
                </div>

                <div className="border-[2px] pt-10 px-3 shadow-lg shadow-gray-400 border-gray-300 rounded-3xl text-center  w-auto h-80 py-8">
                  <center>
                    <img className="" src={TypesSix} alt="loading" />
                    <h2 className="mt-5 text-[28px] font-bold ">
                      Insurance App
                    </h2>
                    <p className="mt-3 text-justify">
                      We can build intuitive insurance apps that simplify policy
                      management, claims, and customer engagement.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* -------Mobile App Development Company in Coimbatore ----- */}

        <section>
          <div className="mt-10 font-RobatoCondesed">
            <div className="flex px-10 max-sm:px-6">
              <div className="w-2 h-12 mt-1 mr-5 bg-orange-500 rounded-lg"></div>
              <h2 className="uppercase  text-[38px]">
                How Our
                <span className="ml-2 mr-2 font-serif text-orange-500">
                  App Developers
                  <br />
                </span>
                do?
              </h2>
            </div>

            <div className="px-16 max-sm:px-6">
              <p className="text-[18px] text-justify mt-8 leading-8">
                Our requirement analysis begins with capturing the client’s
                business practices as much as possible to build a perfect and
                functional app. Our strategic team will work closely with
                developers and clients to gain a full understanding of client
                needs, connecting business objectives with the target goal. This
                makes your business more successful in the mobile world.
              </p>
              <p className="text-[18px] text-justify mt-8 leading-8">
                After collecting your requirements, we initiate the work
                immediately. We work for your goal and will go above and beyond
                with you till the end. By combining our great UI and effective
                app functionalities, the app will work for you in acquiring new
                customers.
              </p>
            </div>
          </div>
        </section>

        {/* ------- Agile Mobile App Development Process ----- */}
        <section>
          <div className="px-16 font-RobatoCondesed py-16 mt-16 mb-10 text-white max-md:px-6 bg-slate-900">
            <div>
              <h2 className="text-[35px]  text-center">
                Agile Mobile App Development Process
              </h2>
              <p className="mt-8 text-center">
                We will make sure your hard-earned time and investment are
                maximized at every stage of the app development process by
                employing an agile methodology and proactive approach. The end
                result will be an effective mobile application that fully
                satisfies your company's needs and, hopefully, goes beyond your
                expectations.
              </p>
            </div>

            <div className="flex mt-10 max-md:flex-col">
              <div className="w-[50%] flex max-md:w-full">
                <div className="w-[20%] ">
                  <img
                    className="object-contain w-auto "
                    src={NotesIcon}
                    alt="notes"
                    title="service-image"
                  />
                </div>

                <div className="w-[70%] ">
                  <h2 className="uppercase text-[22px] tracking-wide font-medium">
                    IDEA FOR A CONCEPT
                  </h2>
                  <p className="mt-3 text-justify">
                    We spend a lot of time discussing concepts for mobile
                    applications after first understanding your business needs.
                    We go step by step in the process of developing perfect
                    mobile experiences.
                  </p>
                </div>
              </div>

              <div className="w-[50%] flex max-md:w-full max-md:mt-3 ">
                <div className="w-[20%] ">
                  <img
                    className="object-contain w-auto "
                    src={SmartPhone}
                    alt="notes"
                    title="SmartPhone"
                  />
                </div>
                <div className="w-[80%]">
                  <h2 className="uppercase text-[22px] tracking-wide font-medium">
                    SmartPhone
                  </h2>
                  <p className="mt-3 text-justify">
                    Our team in India will test every module of the mobile
                    application once it has been developed to guarantee a
                    flawless product launch rather than an imperfect effort.
                    Your mobile application will soon be accessible on the App
                    Store and Play Store after the bugs have been fixed.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[100%] flex mt-10 ">
              <div className="w-[10%] max-sm:w-[25%] ">
                <img
                  className="object-contain w-auto pr-3"
                  src={Medal}
                  alt="notes"
                  title="Medal"
                />
              </div>

              <div className="w-[100%] ">
                <h2 className="uppercase text-[22px] tracking-wide font-medium">
                  SmartPhone
                </h2>
                <p className="mt-3 text-justify">
                  Once the mobile application has been built, our Indian-based
                  team will put the app through essential module testing to
                  ensure a bug-free product launch instead of a try-and-test
                  experiment. When the bugs are fixed, your mobile application
                  will soon be available on App Store /Play Store.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* ------- Agile Mobile App Development Process ----- */}

        <section>
          <div>
            <div className="flex px-16 mt-16">
              <div className="w-2 h-12 mt-1 mr-5 bg-orange-500 rounded-lg"></div>
              <h2 className="text-center text-[38px] font-Nuito uppercase ">
                Technology used by
                <span className="ml-2 mr-2 font-serif text-orange-500">
                  Honey Iconcis
                </span>
                to develop App
              </h2>
            </div>

            <div className="flex px-16 mt-10 max-lg:flex-col max-md:px-6">
              <div className="">
                <div className="w-[100%]   h-auto ">
                  <img
                    className="h-[100%] object-cover w-[100%] "
                    src={Phone}
                    alt="loading"
                    title="Phone"
                  />
                </div>
              </div>
              <div className="w-[100%] ">
                <section className="py-6 ">
                  <div className="container flex flex-col justify-center mx-auto">
                    <div className="grid grid-cols-1 gap-4 pt-8 gap-y-10 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2">
                      <div className="flex shadow-md shadow-gray-400">
                        <div className=" w-[40%]  ">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageOne}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] text-[1.9rem] tracking-wider font-Nuito uppercase pt-4 font-bold  ">
                          Flutter
                        </p>
                      </div>

                      <div className="flex shadow-md shadow-gray-400">
                        <div className=" w-[40%]">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageTwo}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] font-bold font-Nuito uppercase text-[1.9rem] pt-4 tracking-wider  ">
                          Dart
                        </p>
                      </div>

                      <div className="flex mt-2 shadow-md shadow-gray-400">
                        <div className=" w-[40%]">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageThree}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] font-Nuito uppercase text-[1.9rem] pt-4 tracking-wide font-bold">
                          Kotlin
                        </p>
                      </div>

                      <div className="flex mt-2 shadow-md shadow-gray-400">
                        <div className=" w-[40%]">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageFour}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] font-Nuito uppercase text-[1.6rem] pt-5 tracking-wide font-bold ">
                          React Native
                        </p>
                      </div>

                      <div className="flex mt-2 shadow-md shadow-gray-400">
                        <div className=" w-[40%]">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageFive}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] font-Nuito uppercase text-[1.6rem] pt-5 tracking-wide font-bold ">
                          Firebase
                        </p>
                      </div>

                      <div className="flex mt-2 shadow-md shadow-gray-400">
                        <div className=" w-[40%]">
                          <img
                            className="w-[100%] h-20 object-contain"
                            src={TecImageSix}
                            alt="loading"
                            title="backend-language-image"
                          />
                        </div>
                        <p className="w-[60%] font-Nuito uppercase text-[1.6rem] pt-5 tracking-wide font-bold ">
                          PhP
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="px-16 py-5 mb-10 bg-gray-100 max-md:px-6">
            <div className="">
              <h2 className="text-center pt-10 font-medium mb-10 text-orange-500 text-[42px]">
                {" "}
                FAQs{" "}
              </h2>
              <div className="mb-10 space-y-4">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900 ">
                  <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                    What types of apps does Honeyiconics specialize in
                    developing as an app development company in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                    Honeyiconics specializes in developing a wide range of apps
                    for businesses in Coimbatore, including native mobile apps
                    for iOS and Android, cross-platform apps, hybrid apps, web
                    apps, enterprise apps, e-commerce apps, and more.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure the quality and performance of
                    apps developed for clients in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                    Honeyiconics follows industry best practices and rigorous
                    testing methodologies to ensure the quality and performance
                    of apps developed for clients in Coimbatore. We conduct
                    thorough testing, usability testing, performance testing,
                    and compatibility testing to deliver reliable and
                    high-performing apps.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                    Can Honeyiconics develop custom apps tailored to the
                    specific needs of businesses in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                    Yes, Honeyiconics specializes in developing custom apps
                    tailored to the specific needs and requirements of
                    businesses in Coimbatore. We work closely with clients to
                    understand their objectives, target audience, and desired
                    features, and then create customized app solutions that
                    address their unique needs effectively.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                    How does Honeyiconics approach UI/UX design for mobile apps
                    developed for businesses in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                    Honeyiconics takes a user-centric approach to UI/UX design
                    for mobile apps developed for businesses in Coimbatore. We
                    focus on intuitive navigation, appealing visual design,
                    seamless user experience, interactive elements, and
                    usability testing to create engaging and user-friendly
                    mobile apps.
                  </p>
                </details>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
      <MobileSocialIcons />
    </>
  );
};

export default AppDevelopment;
