import React from "react";
import ScrolToTopBtn from "../Scrollings/btn-scroll-to-top";
import Logo from "../../images/Logo/honey-iconcis-logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div>
        <div className="w-full h-auto px-10 py-4 bg-slate-900 ">
          <div className="flex flex-wrap lg:justify-around items-start gap-10">
            {" "}
            <div className="w-auto h-auto lg:mr-5 bg-wh">
              <h2 className="text-[25px]  text-white font-sevenhundered">
                Quick Links
              </h2>

              <div>
                <ul className="mt-8  text-gray-400 font-normal text-[14px]">
                  <li>
                    <Link to={"/about-us-software-development-company-in-Coimbatore"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      ABOUT US
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/portfolio"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      PORTFOLIO{" "}
                    </Link>
                  </li><li className="mt-3">
                    <Link to={"/hire-developers"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      HIRE DEVELOPERS{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/blog"}>
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      BLOG
                    </Link>
                  </li>
                  <li className="mt-3">
                    <span className="text-orange-500">
                      <i className="fa-solid fa-angles-right"></i>
                      {" "}</span>
                    CAREER
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* ---- */}
            <div className="w-auto h-auto lg:pl-10 mr-5 ">
              <h2 className="text-[25px]  text-white font-sevenhundered">
                Service
              </h2>

              <div>
                <ul className="mt-8 text-gray-400  font-normal text-[14px]">
                  <li>
                    <Link to={"/digital-marketing-company-coimbatore"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Digital Marketing{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/website-development-company-in-coimbatore"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Web Development{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/logo-design-branding-services"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      Branding{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/google-advertising-company-coimbatore"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      Google Adwords{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/mobile-app-developers-coimbatore"}>
                      {" "}
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      Mobile App Development{" "}
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link
                      to={"/software-development-company-in-coimbatore"}
                    >
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Custom Software Development
                    </Link>
                  </li>
                  {/* <li className="mt-5">
                    <Link to={"/local-page-seo"}>
                      <span className="text-orange-500">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Local SEO
                    </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/on-page-seo"}>
                      <span className="text-orange-500">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      ON Page SEO
                    </Link>

                  </li>

                  <li className="mt-5">
                    <Link to={"/off-page-seo"}>
                      <span className="text-orange-500">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      OFF Page SEO
                    </Link>

                  </li> */}
                </ul>
              </div>
            </div>{" "}
            <div className="w-auto h-auto lg:pl-10 mr-5 ">
              <h2 className="text-[25px]  text-white font-sevenhundered">
                SEO Services
              </h2>

              <div>
                <ul className="mt-8 text-gray-400  font-normal text-[14px]">
                  <li className="mt-3">
                    <Link to={"/seo-company-in-coimbatore"}>
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Local SEO
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/on-page-seo-services"}>
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      ON Page SEO
                    </Link>
                  </li>

                  <li className="mt-3">
                    <Link to={"/best-seo-services-in-coimbatore"}>
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      OFF Page SEO
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to={"/best-technical-seo"}>
                      <span className="text-orange-500">
                        <i className="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      TECHNICAL SEO
                    </Link>
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* <div className="h-auto lg:pl-16 mr-5 w-80">
              <h2 className="text-[25px]  text-white pt-0 font-sevenhundered">
                Branches
              </h2>

              <div>
                <ul className="mt-8 text-gray-400 font-normal text-[14px] ">
                  <li className="flex mt-5">

                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    No:2/1 Third Street Gokulapuram Chengalpattu-603001


                  </li>
                  <li className="flex mt-5">


                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>


                    No: 97 Sova Appartment, B-Block,kalyani, West Bengal-75423.


                  </li>
                  <li className="flex mt-5">

                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>

                    Paras global kutir, Pattanagere road Rajarajeshwari Nagar,Bengaluru-560098.



                  </li>
                  <li className="flex mt-5">
                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    Malleswaran.P, No 583, B1,6 th cross Ring Road, anjaneyar Temple street, Muneeswar nagar, Hosur, TAMIL NADU 635109

                  </li>
                  <li className="flex mt-5">
                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    Indira Nagar, Siva Salai, SP Bunglow Opp, Devarasampatti, Dharmapuri - 636807

                  </li>
                </ul>
              </div>
            </div>{" "} */}
            <div className="h-auto w-72 lg:pl-5">
              <h2 className="text-[25px]  text-white font-sevenhundered">
                Follow us
              </h2>

              <div>
                <ul className="mt-8 text-gray-400 font-normal text-[14px]">
                  <li className="flex mt-3">
                    {" "}
                    <span className="mr-1 text-orange-500">
                      <i className="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a
                      href="https://maps.app.goo.gl/L6UYNCpD8nCjj4iA9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      185/18, Ani complex, Kamarajar Road, Karamadai, Coimbatore
                      - 641104
                    </a>
                  </li>
                  <li className="flex flex-wrap mt-3">
                    {" "}
                    <span className="mr-1 text-orange-500">
                      <i className="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a
                      href="mailto:info@honeyiconics.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@honeyiconics.in
                    </a>
                    <a
                      href="mailto:support@honeyiconics.com"
                      target="_blank"
                      rel="noreferrer"
                      className="pl-5"
                    >
                      support@honeyiconics.com
                    </a>
                  </li>
                  <li className="flex mt-3">
                    {" "}
                    <span className="mr-1 text-orange-500">
                      <i className="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a
                      href="tel:+918374812424"
                      className="mr-4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91-8374812424
                    </a>{" "}
                    <a
                      href="tel:+916379453263"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91-6379453263
                    </a>
                  </li>
                  <li className="flex mt-3">
                    {" "}
                    <span className="mr-1 text-orange-500">
                      <i className="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a href="tel:04254292660"> 04254-292660</a>
                  </li>
                </ul>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* ---- */}
        <div className="w-full h-auto px-10 bg-gray-950">
          <div className="flex justify-between py-3 max-sm:flex-col">
            <div className="w-[30%] h-auto  max-sm:w-full">
            <Link to={"https://honeyiconics.in/"}>
                <img
                  className="object-contain w-full h-10"
                  src={Logo}
                  alt="loading"
                />
              </Link>
            </div>
            {/* -- */}
            <div className="flex w-full h-auto pl-10 text-justify max-sm:pl-3 max-sm:pr-3 text-cenetr gap-7 max-sm:flex-col">
              <p className="flex flex-wrap justify-center pt-2 text-white max-sm:text-sm">
                <div className="max-sm:ml-3">
                  {" "}
                  Copyright © 2024{" "}
                  <Link to={"https://honeyiconics.in/"}>
                    Honey Iconics
                  </Link>{" "}
                </div>
                <div>
                  {" "}
                  <span className="ml-5 mr-5 "> |</span> All Rights Reserved.{" "}
                </div>
                <div>
                  {" "}
                  <span className="ml-5 mr-5">|</span><Link to={"/privacy-policy"}>
                    Privacy policy
                  </Link>{" "}
                </div>
              </p>
              <div>
                <div className="justify-center w-full h-auto pt-1 pl-16 max-sm:pl-[30%]">
                  <ul className="flex gap-3 ">
                    <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <a href="https://www.facebook.com/honeyiconics" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                          {" "}
                        </div>
                      </div>
                    </li>
                    {/* --- */}
                    <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <a href="https://www.instagram.com/honeyiconics/?hl=en" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                        </div>
                      </div>
                    </li>
                    {/* -- */}
                    <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <a href="https://twitter.com/arul031981" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter "></i></a>
                        </div>
                      </div>
                    </li>
                    <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <a href="https://in.linkedin.com/company/honeyiconics"  rel="noreferrer" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <a href=""  rel="noreferrer" target="_blank"><i className="fa-brands fa-pinterest"></i></a>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----- */}
        <ScrolToTopBtn />
      </div>
    </div>
  );
};

export default Footer;
