import React from 'react'
import { Link } from 'react-router-dom'

export default function Custompage() {
  return (
    <div className='custom-page'>
       <span>Redirect to <Link to="/">Home page</Link></span>
    </div>
  )
}
