import React from "react";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import "../../css-files/development.css";
import Footer from "../../components/footer/footer";
import Headerone from "../../components/NavBars/header-one/headerone";
import NavOne from "../../components/NavBars/Main-Nav-Bar/Nav-one";
import MobileSocialIcons from "../mobile-social-links";
import graphicone from "../../images/graphic-design/design1.jpg";
import graphictwo from "../../images/graphic-design/design2.jpg";
import graphicthree from "../../images/graphic-design/design3.jpg";
import graphicfour from "../../images/graphic-design/design4.jpg";
import graphicfive from "../../images/graphic-design/design5.jpg";
import graphicsix from "../../images/graphic-design/design6.jpg";

export default function Graphicpage() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <NavOne />
      </div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Graphic Design Services in Coimbatore</title>
          <meta
            name="description"
            content="Enhance the visual aspect of your brand by working with a graphic design company in Coimbatore. We create graphically attractive designs that boost your brand's visibility and engage your audience with attention to detail and creativity"
          />
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/website-graphic-design-services"
          />
        </Helmet>
      </div>
      {/* =====================logo================= */}
      <section>
        <div>
          <section className="wrapper pb-10 mb-20 ">
            <div id="stars1"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>

            <div className="flex pt-10 mb-10 max-lg:flex-col">
              <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                <h1
                  id="title"
                  className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                >
                  <span>Graphic Design</span>
                </h1>
                <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                  Boost your online visibility with Coimbatore's best graphic
                  design services. Our team of designers specializes in creating
                  eye-catching visuals that improve the appeal of your website
                  and convey the message of your business. Every visual aspect
                  of your website, from eye-catching banners and icons to
                  attraction graphics and user interface elements, is carefully
                  crafted to match your brand identity and attract your target
                  audience. You can count on us to provide your website with a
                  unique graphic design that makes you stand out from other
                  businesses.
                </p>

                <center className="">
                  <Link to={"/contact"}>
                    {" "}
                    <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button>{" "}
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =====================logo end================== */}

      {/* ==================logo definition=================== */}
      <section>
        <div>
          <div className="px-16  font-RobatoCondesed max-md:px-6">
            <h2 className=" text-[35px] uppercase max-md:text-[18px]">
              Graphic
              <span className="font-serif mr-3 ml-3 text-orange-500">
                Design
              </span>
            </h2>
            <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
              Welcome to Honeyiconics Leading Website Developement Company,
              where we take pride in being India's leading website development
              company. If you're ready to propel your business to new heights,
              we're here to help you create a powerful online presence that
              captivates your audience and drives success.
              <br />
              <br />
              Unlock the full potential of your business with India's top
              website development company. Elevate your online presence, attract
              customers, and boost revenue. Get started today with our expert
              web development services! We understand that every business is
              unique. Our website development services are tailored to your
              specific requirements, ensuring that your online presence reflects
              the essence of your brand.
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      {/* =====================logo definition end========================== */}

      {/* ====================================================== */}

      {/* ========================================================= */}

      {/* =================portfolio======================= */}

      <section className="py-6 container mb-5 ">
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={graphicone} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={graphictwo} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
              <img src={graphicthree} alt="logo" title="logo-image" />
            </div>
          </div>
        </div>
        <div className="container flex flex-col justify-center p-4 mx-auto">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={graphicfour} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500">
              <img src={graphicfive} alt="logo" title="logo-image" />
            </div>

            <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
              <img src={graphicsix} alt="logo" title="logo-image" />
            </div>
          </div>
        </div>
      </section>

      {/* =================portfolio end======================= */}
      {/* -------------------------------------- Faq -- SECTION ------------------------ */}
      <section>
        <div>
          <section className="mb-5 dark:text-gray-100 sec-four">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                <span className="text-orange-500 font-sevenhundered">
                  Frequently{" "}
                </span>{" "}
                Asked Questions
              </h2>
              {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
              <div className="mt-5 space-y-4 ">
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    What graphic design services does Honeyiconics offer as the
                    best graphic designing company in Coimbatore?{" "}
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                    Honeyiconics offers a comprehensive range of graphic design
                    services in Coimbatore, including logo design, branding,
                    print design, digital design, packaging design, marketing
                    collateral, infographics, illustrations, and more.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics approach graphic design projects for
                    businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics takes a strategic approach to graphic design
                    projects for businesses in Coimbatore. We start by
                    understanding the client's objectives, target audience, and
                    brand identity. Our team then focuses on creativity,
                    aesthetics, usability, and brand consistency to deliver
                    impactful and visually appealing designs.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can Honeyiconics create custom graphics that align with the
                    brand identity and messaging of businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, Honeyiconics specializes in creating custom graphics
                    that align with the brand identity, values, and messaging of
                    businesses in Coimbatore. We collaborate closely with
                    clients to understand their vision and create designs that
                    effectively communicate their message to their target
                    audience.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that graphic designs for
                    businesses in Coimbatore are visually engaging and
                    compelling?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics ensures that graphic designs for businesses in
                    Coimbatore are visually engaging and compelling by focusing
                    on creative concepts, typography, color schemes, imagery,
                    layout, and composition. We strive to create designs that
                    captivate audiences and leave a lasting impression.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Can Honeyiconics assist businesses in Coimbatore with
                    branding and visual identity development through graphic
                    design?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Absolutely, Honeyiconics offers branding and visual identity
                    development services through graphic design for businesses
                    in Coimbatore. We help clients develop cohesive brand
                    identities, brand guidelines, logos, marketing materials,
                    and promotional assets that reinforce their brand image and
                    messaging.
                  </p>
                </details>
                {/* <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    Does Honeyiconics provide digital design services such as
                    website graphics, social media graphics, and digital ads for
                    businesses in Coimbatore?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Yes, Honeyiconics provides digital design services such as
                    website graphics, social media graphics, digital ads, email
                    templates, and more for businesses in Coimbatore. We create
                    visually appealing and functional designs optimized for
                    digital platforms and user engagement.
                  </p>
                </details>
                <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                  <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                    How does Honeyiconics ensure that graphic designs for
                    businesses in Coimbatore are optimized for print and digital
                    media?
                  </summary>
                  <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                    Honeyiconics optimizes graphic designs for businesses in
                    Coimbatore for print and digital media by considering
                    factors such as resolution, color modes, file formats,
                    typography, and layout. We ensure that designs are suitable
                    for various mediums and maintain quality and consistency
                    across platforms.
                  </p>
                </details> */}
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* =======================faq end========================== */}
      <Footer />
      <MobileSocialIcons />
    </>
  );
}
