import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/Logo/honey-iconcis-logo.png";
const Headerone = () => {
  const head = [
    {
      name: "HOME",
      id: 1,
      classname: "cname",
      link: "/",
    },
    {
      name: "ABOUT US",
      id: 2,
      classname: "cname",
      link: "/about-us-software-development-company-in-Coimbatore",
    },
    { name: "PORTFOLIO", id: 3, classname: "cname", link: "/portfolio" },

    {name:"HIRE", id :4, classname :"cname", link:"/hire-developers"},

    { name: "BLOG", id: 5, classname: "cname", link: "/blog" },

    { name: "CONTACT", id: 6, classname: "cname", link: "/contact" },

  ];

  return (
    <>
      {/* bg-[#2A2836] */}
      <nav className="flex justify-between w-full lg:h-[80px] p-6 pb-5 md:bg-gray-950 bg-slate-900">
        <div>
          <Link to={"/"}>
            {" "}
            <img className="h-8" src={Logo} alt="loading" />
          </Link>
        </div>

        <div className="flex mr-16 max-md:hidden">
          {head.map((item) => (
            <div className="">
              <ul className="text-white cursor-pointer ">
                <li
                  key={item.name}
                  className="font-normal mr-4 text-xs     text-justify mt-1.5  w-auto  flex"
                >
                  <Link to={item.link}> {item.name}</Link>

                  <div className="h-2 w-[1px] mt-1 ml-2 bg-white"></div>
                </li>
              </ul>
            </div>
          ))}
          <Link to={"/contact"}>
            {" "}
            <button className="h-auto px-3 py-2 rounded-lg font-mono text-xs text-center text-white bg-orange-600 ">
              Get Quote
            </button>{" "}
          </Link>
        </div>
      </nav>
      {/* ------------------------ */}
    </>
  );
};

export default Headerone;
