import React, { useState } from "react";

import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";

const NavOne = () => {
  const [drawer, setDrawer] = useState("false");

  const clickerhandler = () => {
    setDrawer(!drawer);
    console.log(drawer);
  };

  return (
    <section>
        <div className=" lg:h-[48px]  max-md:h-auto   bg-slate-900   shadow-lg ">
          <div className="absolute pt-3 right-5 md:hidden top-3 ">
            <div
              className="w-[100%]  text-right  text-[25px] text-white "
              onClick={clickerhandler}
            >
              {drawer ? <FaBars /> : <AiOutlineClose />}
            </div>
          </div>

          <div
            className={`z-30 flex max-md:flex-col  ${
              drawer ? "max-md:hidden" : "show helo"
            }`}
          >
            <div className="w-full h-full md:text-center ">
              <ul className="md:py-3 max-md:font-semibold max-md:uppercase max-md:flex-col  max-md:pb-5 justify-center  max-lg:pr-10 max-md:pr-0 flex head-ul cursor-default  h-auto md:gap-2 text-[14px] text-black  tracking-wide ">
                <li className="he max-md:pt-2 md:hidden text-white  one max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/"}>Home</Link>
                </li>
                <li className="he max-md:pt-2 md:hidden text-white  one max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/about-us-software-development-company-in-Coimbatore"}>ABOUT US</Link>
                </li>

                <li className="he max-md:pt-2 text-white  one max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/digital-marketing-company-coimbatore"}>Digital Marketing</Link>
                </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                <li className="relative  text-white max-md:h-auto max-md:w-auto servicesmenu max-md:pt-2 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <div className="flex max-md:justify-between">
                    <span className="he ">
                      {" "}
                      <Link to={"local-page-seo"}> </Link> Branding
                    </span>{" "}
                    <IoMdArrowDropdown className="mt-1 ml-1 max-md:mr-10 max-md:text-[20px] " />
                  </div>{" "}
                  <div className="absolute z-20 max-md:static w-80 max-md:px-5 max-md:w-full servicedropdownitem top-5">
                    <div className="py-3 pl-2 pr-2 mt-8 uppercase rounded-md bg-slate-900 max-md:bg-gray-100 max-md:mt-2 innerserviceitem ">
                      <ul className="text-start">
                        <li className="pb-2 he border-b-[1px] max-md:text-black text-white border-b-gray-500">
                          <Link to={"/logo-design-branding-services"}> Logo </Link>
                        </li>
                        <li className="pb-2  text-white max-md:text-black he pt-3 border-b-[1px] border-b-gray-500">
                          <Link to={"/website-graphic-design-services"}>Graphic Design</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                <li className="relative text-white max-md:h-auto max-md:w-auto servicesmenu max-md:pt-2 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                <div className="flex max-md:justify-between">
                  <span className="">
                    <Link to={"/website-development-company-in-coimbatore"}>
                      SEO
                    </Link>
                  </span>
                  <IoMdArrowDropdown className="mt-1 ml-1 max-md:mr-10 max-md:text-[20px]" />
                </div>{" "}
                <div className="absolute z-20 max-md:static w-80 max-md:px-5 max-md:w-full servicedropdownitem top-5">
                  <div className="py-3 pl-2 pr-2 mt-8 uppercase rounded-md bg-slate-900 max-md:bg-gray-100 max-md:mt-2 innerserviceitem">
                    <ul className="text-start">
                      <li className="pb-2 he border-b-[1px] max-md:text-black text-white border-b-gray-500">
                        <Link to={"/seo-company-in-coimbatore"}>Local seo</Link>
                      </li>
                      <li className="pb-2 text-white max-md:text-black he pt-3 border-b-[1px] border-b-gray-500">
                        <Link to={"/on-page-seo-services"}>On page seo</Link>
                      </li>
                      <li className="pt-3 max-md:text-black text-white he border-b-[1px] border-b-gray-500 pb-2">
                        <Link to={"/best-seo-services-in-coimbatore"}>Off page seo</Link>
                      </li>
                      <li className="pt-3 max-md:text-black text-white he border-b-[1px] border-b-gray-500 pb-2">
                        <Link to={"/best-technical-seo"}>Technical seo</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                 <li className="relative text-white max-md:h-auto max-md:w-auto servicesmenu max-md:pt-2 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                <div className="flex max-md:justify-between">
                  <span className="">
                    <Link to={"/website-development-company-in-coimbatore"}>
                      Web Development
                    </Link>
                  </span>
                  <IoMdArrowDropdown className="mt-1 ml-1 max-md:mr-10 max-md:text-[20px]" />
                </div>{" "}
                <div className="absolute z-20 max-md:static w-80 max-md:px-5 max-md:w-full servicedropdownitem top-5">
                  <div className="py-3 pl-2 pr-2 mt-8 uppercase rounded-md bg-slate-900 max-md:bg-gray-100 max-md:mt-2 innerserviceitem">
                    <ul className="text-start">
                      <li className="pb-2 he border-b-[1px] max-md:text-black text-white border-b-gray-500">
                        <Link to={"/static-website-development-company-in-coimbatore"}>Static Website</Link>
                      </li>
                      <li className="pb-2 text-white max-md:text-black he pt-3 border-b-[1px] border-b-gray-500">
                        <Link to={"/dynamic-website-designing-company-in-coimbatore"}>Dynamic Website</Link>
                      </li>
                      <li className="pt-3 max-md:text-black text-white he border-b-[1px] border-b-gray-500 pb-2">
                        <Link to={"/ecommerce-web-design-company-in-coimbatore"}>E-Commerce Website</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                <li className="he text-white max-md:pt-2  one max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/software-development-company-in-coimbatore"}>Software Development</Link>
                </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                <li className="he text-white max-md:pt-2  one max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/google-advertising-company-coimbatore"}>Google Adwords</Link>
                </li>

                <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>

                <li className="he  max-md:pt-3 max-md:pl-5 text-white max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/mobile-app-developers-coimbatore"}>App Development</Link>
                </li>
                {/* <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li> */}
                {/* <li className="text-white max-md:border-b-[1px] max-md:border-b-gray-500 he max-md:pt-3 max-md:pl-5 max-md:pb-2 ">
                  <Link to={"Price-package"}>Price & Package</Link>
                </li> */}
                 {/* <li className="pt-[6px] max-md:hidden">
                  <div className="h-3  w-[1px] bg-orange-400 max-md:hidden"></div>
                </li>
                <li className="text-white max-md:border-b-[1px] max-md:border-b-gray-500 he max-md:pt-3 max-md:pl-5 max-md:pb-2 ">
                  <Link to={"/website-dvelopment-coimbatore"}>Website</Link>
                </li> */}
                <li className="he md:hidden text-white  max-md:pt-3 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/portfolio"}>PORTFOLIO</Link>
                </li>
                <li className="he md:hidden text-white  max-md:pt-3 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/hire-developers"}>HIRE</Link>
                </li>
                <li className="he md:hidden text-white  max-md:pt-3 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/blog"}>BLOG</Link>
                </li>
                <li className="he md:hidden text-white  max-md:pt-3 max-md:pl-5 max-md:pb-2 max-md:border-b-[1px] max-md:border-b-gray-500">
                  <Link to={"/contact"}>CONTACT</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
  );
};

export default NavOne;
